.main-row
    .container
        block header
            .row.row-flex.row-flex-centered.row-flex-justify
                .col-md-6
                    block title
                        h1 Your Teaser Page
                .col-md-6.row-flex.row-flex-right
                    .page-actions
                        block actions
                            //- a.btn.btn-success Take action


        .main-content
            .row
                .col-sm-12
                    block content
                        .row
                            .col-sm-6
                                .panel.panel-primary
                                    .panel-body
                                        block screenshot
                                            //- Something gorgeous
                            .col-sm-6
                                block description
                                    //- Describe what this is about

                                block thread
                                    - var adminStyle = 'border: 2px solid #4baad3'
                                    //- Placeholder
                                    .media
                                        .media-left
                                            img.img-circle(src='https://www.gravatar.com/avatar/2146b557530c4c38c84c93113ff210c3', width='24', height='24', style=adminStyle)
                                        .media-body
                                            block thread-placeholder


                                    for response in feedback
                                        .media
                                            .media-left
                                                img.img-circle(src=response.createdByAvatar, width='24', height='24', style=(response.createdByAdmin ? adminStyle : ''))
                                            .media-body
                                                .row
                                                    .col-xs-6
                                                        p: strong= response.createdByDisplay
                                                    .col-xs-6.text-right
                                                        p.small.text-muted Sent &bull; #{response.updatedAtDisplay}
                                                for message in response.messages
                                                    p #{message}

                                    hr

                                    form.form
                                        .form-group
                                            textarea.form-control(type='text', name='body', rows='3', placeholder="I'd like to see it do this...")
                                        ul.list-inline
                                            li: button.btn.btn-success(type='submit') Share your feedback
                                            li or #[a.text-muted(href='https://calendly.com/appcues/feedback', target='_blank') schedule a call]


                        //- Put something here, like an image.

