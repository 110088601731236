p We'll automatically split your users into two groups. #[a(href='http://docs.appcues.com/article/14-ab-testing-flows', target='_blank') See our documentation] on how this works and ways to see the results of your test.

form
    .checkbox(class=isABTested ? 'active' : '')
        label
            input(type='checkbox', name='abtest', checked=isABTested, disabled=isPublished)
            span
            | Enable A/B testing

        if isABTested
            .content
                select.form-control(name='abgroup', disabled=isPublished)
                    option(value="1", selected=(ABGroup === 1)) Show only to group A
                    option(value="2", selected=(ABGroup === 2)) Show only to group B

                p.help-block Customize which group is the #[strong experiment] and which is the #[strong control.] Want even more power? #[a(href='http://ideas.appcues.com/forums/235761-appcues-ideas/suggestions/14155629-ab-test-flows', target='_blank') #[strong Share your feedback]].
