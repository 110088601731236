form
    .form-group
        - var val = shareUrl || ''
        label URL to share
        input.form-control(type='url', name='shareUrl', placeholder='http://www.appcues.com/', value=val)

    .form-group
        - var val = shareText || ''
        label Short description (optional)
        textarea.form-control(type='text', name='shareText', rows='3', placeholder="Wow, using this product makes me so happy. It's the bacon to my eggs.") #{val}
