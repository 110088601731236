mixin colorpickerInput(attrName, value, label, helpText, styles)
    .form-group(style=styles)
        label.control-label(for=attrName)= label
        .input-group.input-colorpicker-group
            input.form-control(type='text' name=attrName value=value placeholder='#5cb85c')
            span.input-group-addon(style='background-color: #{value}'): i.fa.fa-eyedropper
        if helpText
            p.help-block #{helpText}

mixin booleanInput(attrName, value, label)
    .checkbox
        label.control-label(for=attrName)
            input(type='checkbox' name=attrName checked=value)
            strong= label

mixin styleDivider(label, sublabel)
    .divider
        span= label
        if sublabel
            p.help-block= sublabel

.main-row
    .container.styling-container
        .row.row-flex.row-flex-centered.row-flex-justify
            .col-md-6.header-container
                h1 Themes
            .col-md-6.row-flex.row-flex-right
                .page-actions
                    if cannotPublish
                        span.text-muted.unsaved-changes.hidden Only admins can update themes
                        a.btn.disabled Save &amp; Publish
                    else
                        span.text-muted.unsaved-changes.hidden You have unsaved changes
                        if hasPublishedFlows
                            a.btn.btn-success.save-btn.disabled Save &amp; Publish
                        else
                            a.btn.btn-success.save-btn.disabled Save

        .row.row-flex-justify(style='margin-top:10px')
            .col-md-12
                .panel.panel-default(style='margin-bottom:24px')
                    .panel-body
                        .row.theme-selection-row
                            .col-md-6.choose-theme-section
                                div(style="display:flex; justify-content: space-between; align-items: baseline")
                                    p Choose a theme to edit:
                                    div.theme-id-section
                                        strong Theme ID
                                        div.theme-id #{styleId}

                                select.form-control.style-selector(type='text' name='style')
                                    each style in styles
                                        option(value=style.id selected=(style.id === id))= style.name
                                div.row-flex.row-flex-justify
                                    .row-flex.row-flex-justify(style="margin-top:4px")
                                        if isDefault
                                            a.btn.btn-outline.btn-sm.disabled(style="margin-right:4px") Default Theme
                                        else
                                            a.btn.default-theme-btn.btn-outline.btn-sm(style="margin-right:4px") Set as Default Theme
                                        if associatedContentCount
                                            a.associated-content-btn.btn.btn-outline.btn-sm Used by #{associatedContentCount} flow
                                                unless associatedContentCount == 1
                                                    | s
                                        else
                                            a.btn.btn-outline.btn-sm(disabled) Not in use

                                    unless isDefault || isLoading
                                        div
                                            .text-right
                                                a.text-danger.delete-btn.top-space Delete
                            .col-md-6.new-theme-section
                                p Create a new theme from&nbsp;
                                    em #{name}:
                                .form-inline
                                    .row
                                        .col-sm-12
                                            .form-group.create-form-group(style="width:100%")
                                                input.form-control(type="text" name="newStyleName" placeholder="New theme name" required)
                                                a.btn.btn-default.create-btn.pull-right Create
                        if !isLoading && isStyleObjectVersionOutdated
                            .row
                                .col-md-12
                                    .notice.alert-info
                                        p
                                            b We've upgraded our default styling.
                                            span &nbsp;This update removes the "tooltip arrow color" option, and makes the tooltip arrow color the same as the tooltip background color. We recommend upgrading this theme to the latest styling version.
                                        a.btn.btn-primary.update-theme-version-btn Update theme
                        if previousVersion !== null
                            .row.previewing-upgraded-styling-container
                                .col-md-12
                                    .notice.alert-warning
                                        p
                                            b You're previewing this theme with upgraded styling.
                                            span &nbsp;If it looks good, save this theme, otherwise&nbsp;
                                            a(mailto:dev@appcues.com) let us know if we can help.

        .main-content
            .row
                .style-editor-column.col-md-5
                    .panel.panel-default.styling-panel
                        .panel-body.styling-panel-body
                            ul.nav
                                li.active
                                    a(data-toggle='tab' href='#general' role='tab') General
                                li(style="flex-basis: 30%")
                                    a(data-toggle='tab' href='#modals' role='tab' style="text-align:center") Modals & Slideouts
                                li(style="flex-basis: 30%")
                                    a(data-toggle='tab' href='#hotspots' role='tab' style="text-align:center") Hotspots & Tooltips
                                li
                                    a(data-toggle='tab' href='#advanced' role='tab') CSS
                            .tab-content
                                .tab-pane.active#general
                                    form.styling
                                        +styleDivider('Color')
                                        +colorpickerInput('primaryColor', primaryColor, 'Primary color', "Progress bar and 'Next' buttons.")
                                        +styleDivider('Buttons')
                                        +colorpickerInput('secondaryColor', secondaryColor, 'Back button color')
                                        +colorpickerInput('buttonTextColor', buttonTextColor, 'Button text color')
                                        +colorpickerInput('buttonTextSecondaryColor', buttonTextSecondaryColor, 'Hover button text color', 'This color applies when a user hovers over a button.')
                                        label.control-label(for='buttonBorderStyle') Button roundness
                                        .form-group.radio-button-form.row-flex.row-flex-justify
                                            input#button-boxy.radio-button(value="boxy" type="radio" name="buttonBorderStyle" checked=(buttonBorderStyle === 'boxy'))
                                            label.radio-button-selector(for='button-boxy')
                                                img.radio-button-image(src='/images/styling/buttons/button-boxy.png')
                                                span Boxy

                                            input#button-default.radio-button(value="default" type="radio" name="buttonBorderStyle" checked=(buttonBorderStyle === 'default'))
                                            label.radio-button-selector(for='button-default')
                                                img.radio-button-image(src='/images/styling/buttons/button-default.png')
                                                span Default

                                            input#button-round.radio-button(value="round" type="radio" name="buttonBorderStyle" checked=(buttonBorderStyle === 'round'))
                                            label.radio-button-selector(for='button-round')
                                                img.radio-button-image(src='/images/styling/buttons/button-round.png')
                                                span Round
                                        +styleDivider('Font')
                                        +colorpickerInput('defaultFontColor', defaultFontColor, 'Standard font color')
                                        .form-group
                                            label.control-label(for='titleFont') Title font
                                            select.form-control(type='text' name='titleFont' placeholder='Helvetica Neue')
                                                each fontStack in fontStacks
                                                    if titleFont === fontStack.value
                                                        option(value=fontStack.value selected=true)= fontStack.name
                                                    else
                                                        option(value=fontStack.value)= fontStack.name
                                        .form-group
                                            label.control-label(for='defaultFont') Body text font
                                            select.form-control(type='text' name='defaultFont' placeholder='Helvetica Neue')
                                                each fontStack in fontStacks
                                                    if defaultFont === fontStack.value
                                                        option(value=fontStack.value selected=true)= fontStack.name
                                                    else
                                                        option(value=fontStack.value)= fontStack.name
                                        .form-group.row-flex
                                            .btn.btn-outline.btn-sm.add-google-font-btn Set up Google Fonts or Typekit

                                        .click-through-container Not seeing what you need? Try our #[a.click-through-link(data-toggle='tab' href="#modals" role="tab") modal] or #[a.click-through-link(data-toggle='tab' href="#hotspots" role="tab") hotspot] menus.


                                .tab-pane#modals
                                    form.styling
                                        +styleDivider('Look and Feel')
                                        +colorpickerInput('modalTextColor', modalTextColor, 'Text color')
                                        +colorpickerInput('modalBackgroundColor', modalBackgroundColor, 'Background color')

                                        +styleDivider('Progress Bar')
                                        label.control-label(for='buttonBorderStyle') Visibility
                                        .form-group.radio-button-form.row-flex.row-flex-justify
                                            input#progress-bar-attached.radio-button(value='show' type="radio" name="hideProgressBar" checked=(hideProgressBar == 'show' || hideProgressBar == false))
                                            label.radio-button-selector(for='progress-bar-attached')
                                                img.radio-button-image(src='/images/styling/progress-bar/progress-attached.png')
                                                span Visible

                                            input#progress-bar-none.radio-button(value='hide' type="radio" name="hideProgressBar" checked=(hideProgressBar == 'hide' || hideProgressBar == true))
                                            label.radio-button-selector(for='progress-bar-none')
                                                img.radio-button-image(src='/images/styling/progress-bar/progress-none.png')
                                                span Hidden
                                        +styleDivider('Backdrop')
                                        +colorpickerInput('maskColor', maskColor, 'Backdrop color')
                                        .form-group
                                            label.control-label(for='maskOpacity') Backdrop opacity
                                            .input-rangeslider
                                                input.form-control(type='range' min='0' max='100' name='maskOpacity' value=maskOpacity)

                                        .click-through-container Not seeing what you need? Try our #[a.click-through-link(data-toggle='tab' href='#advanced' role='tab') CSS editor].

                                .tab-pane#hotspots
                                    form.styling
                                        +styleDivider('Look and Feel')
                                        +colorpickerInput('tooltipTextColor', tooltipTextColor, 'Text color')
                                        +colorpickerInput('tooltipColor', tooltipColor, 'Background color')

                                        if !version || version === 1
                                            +colorpickerInput('tooltipArrowColor', tooltipArrowColor, 'Arrow color')
                                        .form-group
                                            label.control-label(for='tooltipRadius') Tooltip corner roundness
                                            .input-rangeslider
                                                input.form-control(type='range' min='0' max='24' name='tooltipRadius' value=tooltipRadius)

                                        +styleDivider('Shadows')

                                        .form-group.radio-button-form.row-flex.row-flex-justify
                                            input#tooltip-shadow-none.radio-button(value="none" type="radio" name="tooltipShadow" checked=(tooltipShadow === 'none'))
                                            label.radio-button-selector(for='tooltip-shadow-none')
                                                img.radio-button-image(src='/images/styling/tooltip-shadows/none-grey.png')
                                                span None

                                            input#tooltip-shadow-solid.radio-button(value="solid" type="radio" name="tooltipShadow" checked=(tooltipShadow === 'solid'))
                                            label.radio-button-selector(for='tooltip-shadow-solid')
                                                img.radio-button-image(src='/images/styling/tooltip-shadows/solid-grey.png')
                                                span Solid

                                            input#tooltip-shadow-blur.radio-button(value="blur" type="radio" name="tooltipShadow" checked=(tooltipShadow === 'blur'))
                                            label.radio-button-selector(for='tooltip-shadow-blur')
                                                img.radio-button-image(src='/images/styling/tooltip-shadows/blur-grey.png')
                                                span Blur
                                        +styleDivider('Beacon')
                                        +colorpickerInput('beaconColor', beaconColor, 'Beacon color')
                                        .form-group
                                            label.control-label(for='beaconStyle') Beacon icon
                                            select.form-control(type='text' name='beaconStyle')
                                                each icon in beaconIcons
                                                    option(value=icon.value selected=(beaconStyle === icon.value))= icon.name
                                        .form-group
                                            label.control-label(for='hotspotAnimation') Beacon animation
                                            select.form-control(type='text' name='hotspotAnimation' placeholder='No Animation')
                                                each animation in hotspotAnimations
                                                    option(value=animation.value selected=(hotspotAnimation === animation.value))= animation.name

                                        .click-through-container Not seeing what you need? Try our #[a.click-through-link(data-toggle='tab' href='#advanced' role='tab') CSS editor].

                                .tab-pane#advanced
                                    .notice.alert-info
                                        p Looking for help with styling?&nbsp;
                                            a(href="http://docs.appcues.com/category/120-styling-customization" target="blank") Read our CSS customization docs
                                    .divider
                                        span Edit custom CSS
                                        a.expand Expand
                                    .ace-editor#css-editor= customCss
                .col-md-7.flow-preview-column
                    .panel.panel-default.styling-preview-panel
                        a(name='preview-modal')
                        .panel-heading
                            .row.panel-title-row
                                h3.panel-title.divider-title Interactive Preview
                                a#refresh
                                    i.fa.fa-refresh
                        .panel-body
                            if isLoading
                                p Loading...
                            else
                                .row
                                    .col-md-12.styling-preview-frame.styling-preview-frame-flow
                                a(name='preview-hotspot')
                                div
                                    .col-md-12.styling-preview-frame.styling-preview-frame-hotspots
                                    .col-md-12.styling-preview-frame.styling-preview-frame-slideout
