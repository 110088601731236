ul
    if step.stepType == 'video'
        li
            a.show-video-hint
                i.fa.fa-fw.fa-lightbulb-o
    if step.stepType == 'social'
        li
            a.show-social-settings
                i.fa.fa-fw.fa-cog

