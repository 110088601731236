extends configure-step

block content
    header
        h2 Configure the accounts your users will authenticate against.
        p Appcues will ask your customers to give you access to their social media accounts. In order for us to do that, we need your social media information.

    hr

    form(role='form')
        .row
            .col-md-3.text-center
                p Setup Twitter
                i.fa.fa-twitter.fa-5x.fa-fw

            .col-md-9
                .form-group
                    label(for='twitter-consumer-key') Consumer key
                    input#twitter-consumer-key.form-control(type='text', placeholder='Your name', name='twitter-consumer-key')

                .form-group
                    label(for='twitter-consumer-secret') Consumer secret
                    input#twitter-consumer-secret.form-control(type='text', placeholder='Your name', name='twitter-consumer-secret')

        hr

        .row
            .col-md-3.text-center
                p Setup Facebook
                i.fa.fa-facebook-square.fa-5x.fa-fw

            .col-md-9
                .form-group
                    label(for='facebook-application-id') Application id
                    input#facebook-application-id.form-control(type='text', placeholder='Your name', name='facebook-application-id')

                .form-group
                    label(for='facebook-application-domain') Application domain
                    input#facebook-application-domain.form-control(type='text', placeholder='Your name', name='facebook-application-domain')

                .form-group
                    label(for='facebook-client-secret') Client secret
                    input#facebook-client-secret.form-control(type='text', placeholder='Your name', name='facebook-client-secret')

        hr

        .row
            .col-md-3.text-center
                p Setup LinkedIn
                i.fa.fa-linkedin-square.fa-5x.fa-fw

            .col-md-9
                .form-group
                    label(for='linkedin-api-key') Consumer key
                    input#linkedin-api-key.form-control(type='text', placeholder='Your name', name='linkedin-api-key')

                .form-group
                    label(for='linkedin-api-secret') Consumer secret
                input#linkedin-api-secret.form-control(type='text', placeholder='Your name', name='linkedin-api-secret')
