.modal-dialog
    .modal-content
        .modal-header
            a.close(data-dismiss="modal") &times;
            h4.modal-title Manage Fonts

        .modal-body
            label(style="width:100%; margin-bottom: 16px;")
                strong(style="margin-bottom: 8px;display: block;") Typekit ID
                input.form-control.typekit-id-field(type="url" name='typekitId' placeholder='ifb4eeb' value=typekitId required)
                span.text-caption You can’t currently select Typekit fonts from the font menu, but you can use the loaded font by including the Typekit font-family names in your custom CSS.
            if customFonts.length > 0
                section.currentUrl(style="margin-bottom: 16px")
                    .row-flex.row-flex-justify(style="align-items:center;")
                        h5 Google font import URL
                        a.clear-current-font(style="color: #DA3E0D; font-size: 14px") Remove Font(s)
                    pre(style='display:flex; flex-direction:row; align-items:flex-start')
                        a(href=permalink, target='_blank', style='flex-grow:1; white-space:normal; align-self:center')= customUrl
                        button.btn.btn-primary.btn-sm.change-current-font Change
                section.currentFonts
                    span.text-caption These are the fonts included in the url provided. They will be selectable under the dropdown for 'Title' and 'Body' fonts.
                    for font, index in customFonts
                        .row-flex.row-flex-justify(style="align-items: center; padding: 10px 20px; margin: 10px 0; width: 100%")
                                strong= font.name
            else
                form.add-font-form(style="display:flex; margin-bottom: 16px;")

                    label(style="width:100%; margin-bottom: 0;")
                        strong(style="margin-bottom: 8px;display: block;") Google font import URL
                        input.form-control.custom-url-field(type="url" name='googleLink' placeholder='https://fonts.googleapis.com/css?family=Raleway' value=googleLink required)
                        span.text-caption Find this url by clicking 'Select This Font' on the google fonts page
                        span.text-caption.form-error-msg.hidden(style="color:#DA3E0D; display: block;") Error processing this url.

                    button.btn.btn-block.btn-primary(type='submit' style="flex-basis: 20%; margin-left: 4px; align-self: flex-start; margin-top: 30px;") Add font(s)

            .row.row-flex.row-justify
                button.btn.btn-block.btn-primary.save-fonts Save custom font(s)
