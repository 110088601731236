.radio
    label
        input(type="radio" name="frequency" value="once" checked=(rule.frequency=='once') disabled=rule.published)
        span
        | Show only on the next visit to a matching page

.radio
    label
        input(type="radio" name="frequency" value="every_time" checked=(rule.frequency=='every_time') disabled=rule.published)
        span
        | Show on every visit to matching pages

