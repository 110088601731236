extends modal

block header
    h4.modal-title Step Information

block body
    form.form
        .form-group
            label Step ID
            input.form-control(type='text' name='stepId', value=model.id readonly=true)

block footer
    a.btn.btn-link(data-dismiss='modal') Close

