extends install-guide
block stepContent
    .panel-heading.panel-heading-active.panel-heading-instructions(data-toggle='collapse', data-target='#instructionsBody', aria-expanded='false', aria-controls='instructionsBody')
        h3.panel-title
            | 2. Installing with our javascript SDK
    .panel-body#instructionsBody.collapse.in
        .text-center
            p Select your backend language below:
        .code-snippet
        br
        p.btn.btn-success.pull-right#next Okay got it!
