.modal-dialog.modal-center(class="modal-#{type || 'default'}")
    .modal-content
        .modal-body.text-center
            block body
                if title
                    h4= title

                if content
                    != content

        .modal-footer.text-right
            block footer
                - var btnText = buttonText || 'Ok, got it'
                button.btn.btn-link(data-event='confirm', type='button')= btnText
