.container

    .invite-dev-container
        h3 Send Installation Instructions

        p Appcues installation is not difficult, but it does require inserting our code snippet into your app. It should take a developer less than 10 minutes in general. Developers may have concerns about using a third-party tool, so we've set up&nbsp;
            a(href="http://docs.appcues.com/article/159-faq", target="_blank") this FAQ
            | &nbsp;that you can send to them.

        p Our&nbsp;
            a(href="http://docs.appcues.com/article/48-install-overview", target="_blank") installation overview
            | &nbsp;goes into more detail about the Appcues installation.

        .panel-default.invite-section
            h4.invite-header Who do you want to send instructions to?

            form.form-inline#invite
                .form-group
                    label(for='firstname') First Name
                    input.form-control(type='text', name='firstname', id='firstname', placeholder='First', autofocus, required)
                .form-group
                    label(for='lastname') Last Name
                    input.form-control(type='text', name='lastname', id='lastname', placeholder='Last', required)
                .form-group.email-group
                    label(for='email') Email
                    input.form-control(type='email', name='email', id='email', placeholder='Email')

        .panel-default.invite-section
            h4.invite-header(style="margin-bottom: 5px") Make a Personal Intro
            p.invite-desc Give some context and explain what you'd like to do with Appcues.
            .panel.email-body(contenteditable="true")

                p Here's some information on installing Appcues, a user onboarding and messaging tool that we want to try.

            p.preview Preview the full email.

            .preview-backdrop.hidden
                .preview-controls
                    .fa.fa-refresh
                    .fa.fa-compress
                .preview-email
                    .preview-header
                        .preview-recipient
                            p To:
                                span.recipient-firstname Firstname
                                span.recipient-email Email
                        .preview-subject
                            p Subject:
                                span.subject #{subject}
                    hr
                    .preview-body
                        p.preview-text

                        .preview-bottom
                            p Thanks for your help!
                            p &nbsp;
                            p ---------------------------
                            p What is Appcues?
                            p Appcues is a platform for creating walkthroughs, feature notifications, and more, with no coding required. It's styleable with CSS and fits right into your app's UX.
                            p Below are guides for installation:
                            p ------------------
                            p ------ &nbsp;Appcues FAQ: http://docs.appcues.com/article/159-faq
                            p ------ &nbsp;Installation Guide: http://my.appcues.com/install/#{accountId}
                            p ------ &nbsp;Technical Overview: http://docs.appcues.com/article/263-installation-technical-overview
                            p ------------------
                            p Have any questions about Appcues? Check out our docs: http://docs.appcues.com/
                                br
                                | You can also reply to this email or contact support@appcues.com, we'll be happy to answer any questions.

        .send-instructions
            button.btn.btn-primary.send-instructions-btn(type='click')
                i.fa.fa-paper-plane
                | Send

            p(style="display:inline; margin: 5px 12px 0 12px") or, use this shareable link
                .share
                    input(value="https://my.appcues.com/install/#{accountId}", readonly)
                    .fa.fa-check(style="color:lightgreen")
                    button.btn.btn-sm.btn-primary Copy


        .panel-default.invite-section-cta
            h4 Invite to Appcues
            p You can also invite this person to your Appcues account, although it’s not required. Do you want to make them an account?
            button.open-mailto.btn.btn-primary(type='submit', form='invite')
                i.fa.fa-users
                | Invite to This Account

            .alert-block

                span.success-message

                span.error-default-message.hidden Oops! We encountered an error with this request. Send us an email at
                    a(href="mailto:support@appcues.com?Subject=Invite%20Help" target="_self") support@appcues.com
                    | &nbsp;and we'll help you out. &nbsp;

                    span.error-message
