unless useEager
    .build-bar-setting.pull-right
        a(data-action='initExtensionBuilder') Build in a new window

ul.thumbnails-list.thumbnails-list-sequence.list-group.list-inline
    if collection
        - var i = 0
        each item in collection
            if item.id
                - i += 1
                li.thumbnail-item.list-group-item.draggable-list-group-item(class=(item.expanded ? ' selected ' : '') + (isEditable ? ' editable ' : '') + (item.nodeMissing ? ' nodeMissing ' : '') data-id=item.id data-action='selectItem' )
                    span.thumb(class=(item.nodeMissing ? 'hint--top' : '') data-hint=(item.nodeMissing ? 'Could not find selected element' : ''))= i

                    .thumbnail-controls
                        a(data-action='deleteItem')
                            i.fa.fa-fw.fa-trash


    li.thumbnail-item.list-group-item.placeCoachmark
        span.thumb(data-action='placeCoachmark'): i.fa.fa-plus


