include content-mixins
- var settingsUrl = '/' + prefix + '/' + model.id + '/settings'
- var isArchived = model.state === 'ARCHIVED'


td.icon
    a(href=detailsUrl)
        if model.hotspots && _.size(model.hotspots) === 1
            img(src="/images/content-list/#{prefix}-single.png", width="64", height="64")
        else
            img(src="/images/content-list/#{prefix}-multiple.png", width="64", height="64")

td.details
    +contentDetails(model, user, createdBy, lastEditedBy, detailsUrl)

td.target.text-left.hidden-sm
    +pageTargeting(settingsUrl, rule)

td.stats.text-left
    +contentStats(model, stats)

td.target.text-left
    a(href=settingsUrl)
        +userTargeting(isUserTargeted, targetedBy, rule)

unless isArchived
    td.status.text-left
        a(href=settingsUrl)
            +contentState(model, isInstalled)

td.action
    a.on-hover.hint--bottom(data-hint="Edit" href="/#{prefix}/#{model.id}/crx" target="_blank"): i.fa.fa-fw.fa-pencil-square-o

td.action
    a.on-hover.hint--bottom(href="/#{prefix}/#{model.id}/analytics" data-hint="View Reporting"): i.fa.fa-fw.fa-bar-chart-o

td.action
    a.on-hover.hint--bottom(href="/#{prefix}/#{model.id}/settings" data-hint="Settings"): i.fa.fa-fw.fa-gear

td.action
    a.on-hover.hint--bottom(data-action='clone' data-hint="Clone #{prefix}"): i.fa.fa-fw.fa-copy

td.action
    if model.state === 'ARCHIVED'
        a.on-hover.hint--bottom(data-action='delete' data-hint="Delete #{prefix}"): i.fa.fa-fw.fa-trash
    else
        a.on-hover.hint--bottom(data-action='archive' data-hint="Archive #{prefix}"): i.fa.fa-fw.fa-times

if isArchived
    td.action
        a.on-hover.hint--bottom(data-action='unarchive' data-hint="Restore #{prefix}"): i.fa.fa-fw.fa-upload
