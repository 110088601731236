.panel.panel-default
    .panel-body
        block profile

        .message-body
            block body

        .message-actions
            block actions

        .powered-by.text-muted: small Powered by Appcues
