ul.thumbnails-list.thumbnails-list-sequence.list-group.list-inline
    if hotspots
        each hotspot in hotspots
            if hotspot.id
                - var isSelected = hotspot.id == selectedId
                li.thumbnail-item.list-group-item.draggable-list-group-item.text-center(class=(isSelected ? ' selected ' : '') + (isSequential ? 'sequential ' : '') data-id=hotspot.id)
                    if hotspot.nodeMissing
                        img(src="/images/icons/hotspot-missing.png", width='70', height='70')
                    else
                        img(src="/images/icons/hotspot.png", width='70', height='70')

                    .thumbnail-controls
                        a.edit-hotspot
                            if isEditable
                                i.fa.fa-fw.fa-pencil
                            else
                                i.fa.fa-fw.fa-eye
                        if isEditable
                            a.delete-hotspot
                                i.fa.fa-fw.fa-trash
            else
                if isEditable
                    li.thumbnail-item.list-group-item.dashed.text-center
                        img(src="/images/icons/hotspot-add.png", width='70', height='70')
