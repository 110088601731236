.standaloneContainer.frameBlurred
    .previewFrameOverlay
        .modal.centered
            h1 Let's get started
            h2 We're here to help you create an amazing onboarding experience for your users. Start with one of these suggestions, or
                a(href='/build') create your own
                | .

            .templateSelectionContainer
                .selectTemplateButton(data-action='cloneWelcomeTemplate' data-template='welcome')
                    .templateImageContainer
                        img(style="height: 69px" src='/images/onboarding/welcome_message.png')
                    h2 Create a Welcome Message
                    .templateDescription
                        | Introduce yourself to your new users with a personal message.

                .selectTemplateButton(data-action='cloneAnnouncementTemplate' data-template='announcement')
                    .templateImageContainer
                        img(style="height: 76px" src='/images/onboarding/slideout_announcement.png')
                    h2 Create a Slide-out Announcement
                    .templateDescription
                        | Announce a new feature or offer users help with an unobtrusive message.

                .selectTemplateButton(data-action="clonePageTourTemplate" data-template="pagetour")
                    .templateImageContainer
                        img(style="height: 69px" src='/images/onboarding/product_or_feature_tour.png')
                    h2 Create a Product Tour
                    .templateDescription
                        | Show your users around a page in your app with guided tooltips.

            .liveFooter
                h2 Not here to create a new experience? You can check out how to
                    a(href='/install/' + accountId) embed Appcues
                    | on your site,
                    a(href='/styling') customize the appearance
                    | of Appcues, or
                    a(href='mailto:support@appcues.com') contact us
                    | .


