h4 Which users should see this?

if isPublished
    if hasValidFilters
        .properties-summary
            each v, k in properties
                - operator = operatorDisplay(k, v)
                - value = valueDisplay(k, v)
                p
                    strong= k
                    | &nbsp;
                    span= operator
                    | &nbsp;
                    strong= value
    else
        p.no-filters
            | All users
            unless isPublished
                | or&nbsp;
                a.add-rule-property add a filter

else
    if hasProperties || hasValidFilters
        form.properties

        unless hasValidFilters
            p.no-filters
                | All users or&nbsp;
                a.add-rule-property add a filter

        unless isPublished
            a.add-rule-property.btn.btn-primary.hidden Add a filter

    else
        p
            | You haven't installed the Appcues embed script yet. Once you do, all of your user properties will automatically appear here for you to create segments. View your personalized installation instructions&nbsp;
            a(href="/install/a/#{accountId}", target='_blank') here
            | .
