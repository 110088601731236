.modal-dialog
    .modal-content
        .modal-header
            a.close(data-dismiss="modal") &times;
            h4.modal-title Sharing options

        .modal-body
            p: strong Shareable permalink

            p You can trigger this flow to appear by appending #[code ?appcue=#{flowId}] to the end of any URL in your app. Give it a try:

            pre(style='display:flex; flex-direction:row; align-items:flex-start')
                a(href=previewUrl, target='_blank', style='flex-grow:1; white-space:normal; align-self:center')= previewUrl
                button.btn.btn-primary.btn-sm.copy Copy

            p.help-block You can use this in emails, help docs or anywhere your users can open URL links.

            hr

            p: strong Embeddable button

            p Add a button that your users can click to bring up this flow. Here's an example:

            .well.text-center
                button.btn.btn-primary.btn-sm Show hints &#x27a4;

            p Copy the code below to use it:

            pre(style='display:flex; flex-direction:row; align-items:flex-start')
                code(style="flex-grow:1; white-space:normal")
                    | &lt;a class="btn btn-primary btn-sm" href="javascript:Appcues.show('#{flowId}')"&gt;Show hints &amp;#x27a4;&lt;/a&gt;
                button.btn.btn-primary.btn-sm.copy Copy

            hr

            p.help-block
                | This flow's ID is #[code= flowId]#[button.btn.btn-primary.btn-sm.copy Copy]

        .modal-footer
            a.btn.btn-link(data-dismiss='modal') Close
