.modal-dialog
    .modal-content
        .modal-header
            a.close(data-dismiss="modal") &times;
            h4.modal-title Create a new bookmark

        .modal-body
            p.text-center Save this URL target as a bookmark that can be reused for targeting other content.

            hr

            form.form-horizontal
                .form-group
                    label.control-label.col-sm-2 Name
                    .col-sm-10
                        input.form-control(name='name' placeholder='e.g. "Account page" or "Dashboard"')

                .form-group
                    label.control-label.col-sm-2 Target
                    .col-sm-10
                        .form-control-static
                            if attrs.isRegex
                                span Pages that match #[strong= attrs.where] as a regular expression
                            else
                                span Pages that match #[strong= attrs.where] exactly

        .modal-footer
            a.btn.btn-default(data-dismiss="modal") Cancel
            a.btn.btn-primary(data-action="save") Save

