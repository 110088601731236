include mixins/snippets

script(src="//static.segment.com/enable-button/v1/index.js")

div
    .row: .col-md-8.col-md-offset-2
        p.text-center(style="margin-bottom: 15px") If you've already got 
          a(href="https://segment.com/", target="_blank") Segment
          |  enabled on your site, you can set up Appcues instantly:
    .text-center(style="margin-bottom: 35px")
        .btn-group
            div.segment-enable-button(data-integration="appcues" data-settings={ appcuesId: this.meta.get("accountId"), apiKey: this.meta.get("apiKey")} data-redirect-url="#{window.__env.SITE_ORIGIN}/segment-enabled?appcue=-JzVdegbbClYa_9OVe6W" style="display: inline-block;")
