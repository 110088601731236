.modal-dialog
    .modal-content
        .modal-header
            button.close(type='button', data-dismiss='modal', aria-hidden='true') &times;
            block header
                h4.modal-title
                    if content
                        = title
                    else
                        | This feature isn't seaworthy yet.


        .modal-body
            block body
                if content
                    != content
                else
                    .form-content
                        p We're surveying interest for an analytics feature. What were you expecting to see here? What analytics data do you think would be helpful in managing your flows?
                        p Let us know! And if you really like the idea, ask about getting access to beta!

                        br

                        form(method='post', action='/__/forms/beta')
                            .form-group
                                input.form-control(name="fullname" type="text" placeholder="Name", value=fullname)
                            .form-group
                                input.form-control(name="email" type="text" placeholder="Email" value=email)
                            .form-group
                                textarea.form-control(name='message' rows='3', placeholder="I want to know what percentage of people are completing my flows...")
                            br
                            .form-group(style="margin-bottom: 0")
                                button.btn.btn-primary.btn-block(type="submit") Request access

                    .form-success.hidden
                        p Thanks! We'll be in touch! If you have anything else to add, just email support@appcues.com.
                    .form-error.hidden
                        p Whoops, looks like something went wrong. Don't worry, we're on it!


        .modal-footer
            block footer
                if content
                    a.btn.btn-link(data-dismiss='modal') Cancel
                    button.btn.btn-primary.modal-save(type='button') Save changes
                else
                    button(data-dismiss='modal').btn.btn-default Close
