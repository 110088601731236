extends modal

block header
    h4.modal-title Claim your content by creating an account.

block body
    form(role='form')
        .form-group
            label(for='email-address') Email address
            input#email-address.form-control(name='email', type='email', placeholder='Enter email')
        .form-group
            label(for='password') Password
            input#password.form-control(name='password', type='password', placeholder='Password')

block footer
    button.btn.btn-primary.modal-save(type='button') Claim my content
