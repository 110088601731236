extends step-action

block content
    if isEditable
        textarea.redactor
            if content
                != content
            else
                br
                br
                p(style='text-align: center') Add your own short, fun product video. 1-3 minutes ought to do it.
                br
                br

                hr

                p(style='text-align: center')
                    | Add a brief description of the video you've included. A short teaser could help increase the play rate.

    else
        != content

