.appcues-preview-frame-message-container
    .appcues-preview-frame-message.container
        .row
            .col-md-12
                h2 Allow mixed content on this page
        .row
            .col-md-8
                p It looks like you might be loading your page over HTTP. If you can, try loading it over HTTPS.
                | Otherwise, we'll just need you to allow mixed content for this page by clicking on the little badge up in the address bar and choosing #[strong "Load unsafe scripts"]. Once you do that, you should be on your way to creating some great Appcues content!
            .col-md-4.img-container
                img(src="/images/mixed-content.png")
