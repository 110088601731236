mixin contentDetails(content, user, createdBy, lastEditedBy, linkUrl)
    div: strong: a(href=linkUrl)= content.name
        small.on-hover.i.fa.fa-fw.fa-bar-chart-o(style="margin-left: 8px")
    if user && user.avatarUrl
        img.img-avatar.img-circle(src=user.avatarUrl width='20' height='20')
    if content.updatedAt
        - d = moment(content.updatedAt)
        - hasLastEditedByFullName = (lastEditedBy && lastEditedBy.fullname)
        - hasBothUpdatedAtAndCreatedAt = content.updatedAt && content.createdAt
        small.text-muted updated on #{d.format('M/D/YY')}#{hasLastEditedByFullName && " by " + lastEditedBy.fullname}#{ hasBothUpdatedAtAndCreatedAt && ", "}
    if content.createdAt
        - d = moment(content.createdAt)
        - hasCreatedByFullName = (createdBy && createdBy.fullname)
        - wasCreatedBySomeoneOtherThanWhoeverLastEditedThis = createdBy && ((!lastEditedBy) || (createdBy.id != lastEditedBy.id))
        - shouldShowCreatedByName = hasCreatedByFullName && wasCreatedBySomeoneOtherThanWhoeverLastEditedThis
        small.text-muted created on #{d.format('M/D/YY')}#{shouldShowCreatedByName ? " by " + createdBy.fullname : ""}

mixin pageTargeting(url, rule)
    if rule && !rule.migratedAt
        - var hintClass = rule.previewUrl ? 'hint--bottom' : ''
        - var hintText = rule.previewUrl ? ('E.g. ' + rule.previewUrl) : false

        a(href=url, class=hintClass data-hint=hintText)
            span.one-liner(style='max-width: 110px')= rule.where
            br
            small.text-muted.on-hover
                if rule.whereHash === 'regex'
                    | Pattern match
                else
                    | Exact match

mixin userTargeting(isTargeted, targetedBy, rule)
    if rule && !rule.migratedAt
        if isTargeted
            span Targeted
            br
            small.text-muted.on-hover
                if targetedBy.length == 1
                    | by #{targetedBy[0]}
                else
                    | by #{targetedBy.length} attributes
        else
            span All users

mixin contentState(content, isInstalled)
    if content.published
        if isInstalled
            span.label.label-success
                small: i.fa.fa-circle
                | &nbsp; Live
        else
            span.label.label-warning Pending

    else
        span.label.label-default Draft

mixin contentStats(model, stats)
    - var allTimeStats = stats['all-time-unique']
    if allTimeStats && allTimeStats.reached
        div(data-action='showAnalytics')
            div(class=(allTimeStats.updatedAt ? 'hint--bottom' : '') data-hint="As of #{moment(allTimeStats.lastUpdated).fromNow()}")
                span= allTimeStats.completed
                span.text-muted &nbsp;/&nbsp;
                span= allTimeStats.reached
                br
                small.text-muted.on-hover Completed / Reached
    else
        if model.published
            span 0
            br
            small.text-muted.on-hover Users reached
        else
            span.on-hover.hint--bottom(data-hint='No users have seen this yet') N/A
