if model.stepsAnalytics && model.stepsAnalytics.length > 0
    .panel.panel-default
        .panel-heading
            .row
                .col-sm-8
                    | Current Version
                    span.text-muted &nbsp;published #{versionPublished}
                .col-sm-4.text-right
                    small.text-muted
                        if keenUpdating
                            i.fa.fa-circle-o-notch.fa-spin
                            | &nbsp;Updating
                        else
                            | Updated #{funnelUpdated}

        .panel-body
            .inline-list
                - showDuration = false
                - var width = model.goalAnalytics ? Math.round(100.0 / (model.stepsAnalytics.length + 1)) : Math.round(100.0 / model.stepsAnalytics.length)
                each step, i in model.stepsAnalytics
                    .step-column.text-center(style="width: #{width}%")
                        .step-label
                            if i == 0
                                | Start
                            else
                                | #{step.completionPercentage}%
                        .step-users(style="font-size: small; color: #999;")
                            if step.views != null
                                = step.views
                                if step.views == 1
                                    | &nbsp;user
                                else
                                    | &nbsp;users
                            else
                                | &nbsp;
                        .step-wrapper
                            if isGoal
                                .step-bar.goal-bar(style="height: #{Math.round(step.completionPercentage || 1)}px")
                            else
                                .step-bar(style="height: #{Math.round(step.completionPercentage || 1)}px")
                        if step.duration
                            - var duration = Math.round(step.duration / 1000)
                            .step-duration
                                = duration
                                if duration == 1
                                    | &nbsp;second
                                else
                                    | &nbsp;seconds
                        else if !isGoal
                            .step-duration &nbsp;
                        if isGoal
                            if goal.where
                                .step-goal.hint--top(data-hint="Visited #{goal.where}") Goal
                            else
                                .step-goal Goal
                if model.goalAnalytics
                    .step-column.text-center(style="width: #{width}%")
                        .step-label
                                | #{model.goalAnalytics.completionPercentage}%
                        .step-users(style="font-size: small; color: #999;")
                            = model.goalAnalytics.reachedGoal
                            if model.goalAnalytics.reachedGoal == 1
                                | &nbsp;user
                            else
                                | &nbsp;users
                        .step-wrapper
                                .step-bar.goal-bar(style="height: #{Math.round(model.goalAnalytics.completionPercentage || 1)}px")
                            if goal.where
                                .step-goal.hint--top(data-hint="Saw the flow and visited #{goal.where}") Goal
                            else
                                .step-goal Goal

else if !model.stepsAnalytics
    br
    br
    .text-center(style="color: #999")
        i.fa.fa-circle-o-notch.fa-spin.fa-2x
    br
    br
