p Creating a video can be daunting, but it doesn't have to be so hard. Here are a few things to help you succeed with video.

h4 Video creation tools:

ul
    li
        h5
            a(href='http://quickcast.io/', target='_blank') Quickcast
            span.text-muted  - Free
            small  (Mac only)
        p Quickcast is a free, light-weight screencasting tool for Mac OS X. They even host the videos for you.

    li
        h5
            a(href='http://www.telestream.net/screenflow/overview.htm', target='_blank') ScreenFlow
            span.text-muted  - $99
        p ScreenFlow does both screen recording and editing.

h4 Video hosting tools:

ul
    li
        h5
            a(href='http://wistia.com/', target='_blank') Wistia
            span.text-muted  - Free
        p Wistia specializes in business video hosting. They're free and have a ton of great features.

p When creating your video, be comfortable. Create a script beforehand and read it aloud several times. When doing a screencast, record the audio first, then listen to it while you record your screen. Don't do both things at once.

p
    | For more on-camera tips, check out Wistia's free&nbsp;
    a(href='http://wistia.com/learning', target='_blank') Learning Center
    | .
    |  Those guys rock.
