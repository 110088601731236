.component-choices.appcues-style
    .component-choice
        a.control-link(data-action='setType' data-component-type='editor')
            i.icon-pen
            | Text
    .component-choice
        a.control-link(data-action='setType' data-component-type='image')
            i.icon-image-outline
            | Image
    .component-choice
        a.control-link(data-action='setType' data-component-type='video')
            i.icon-film
            | Video
    .component-choice
        a.control-link(data-action='setType' data-component-type='form')
            i.icon-clipboard
            | Form
    //.component-choice
        //a.control-link(data-action='setType' data-component-type='social')
            //i.icon-message
            //| Social

