p
  a(href='http://docs.appcues.com/article/156-event-targeting' target='_blank')
    | Learn more about event targeting.

.radio(class=(showTargetEvents ? '' : 'active'))
    label
        input(type="radio" name="toggleEvents" value="all" checked=(showTargetEvents ? false : true) disabled=isPublished)
        span
        | Any event history

.radio(class=(positiveMatch ? 'active' : ''))
    label
        input(type="radio" name="toggleEvents" value="positiveMatch" checked=(positiveMatch ? true : false) disabled=isPublished)
        span
        | Users who have experienced an event

.radio(class=(negativeMatch ? 'active' : ''))
    label
        input(type="radio" name="toggleEvents" value="negativeMatch" checked=(negativeMatch ? true : false) disabled=isPublished)
        span
        | Users who have not experienced an event

.content(class=(showTargetEvents ? 'active' : ''))
    if eventNames.length > 0
        select#targetEventName.form-control(name='targetEventName' disabled=isPublished)
            option(value="" selected=(!ruleTargetEventName ? 'selected' : false))
            each eventName in eventNames
                - selected = (ruleTargetEventName && (ruleTargetEventName === eventName))
                option(value=eventName data-data={name: eventName} selected=(selected ? 'selected' : false))= eventName
    else
        .help-block Whoops! Looks like you haven't sent us any third-party events yet.


