.field-type-buttons
    .field-type-btn
        .btn.btn-block.btn-default.choose-field-type(data-field-type='text' title='Text')
            i.fa.fa-font
            span &nbsp;Text
    .field-type-btn
        .btn.btn-block.btn-default.choose-field-type(data-field-type='textarea' title='Textarea')
            i.fa.fa-align-left
            span &nbsp;Textarea
    .field-type-btn
        .btn.btn-block.btn-default.choose-field-type(data-field-type='choice' title='Choice')
            i.fa.fa-list-ul
            span &nbsp;Choice
    .field-type-btn
        .btn.btn-block.btn-default.choose-field-type(data-field-type='rating' title='Rating')
            i.fa.fa-star
            span &nbsp;Rating
    .field-type-btn
        a.btn.btn-link.control-link(data-action='removeField' title='Remove')
            i.fa.fa-trash