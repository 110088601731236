.appcues-preview-frame-message-container
    .appcues-preview-frame-message.container
        .row
            .col-md-12
                h2 Launch the Appcues Chrome Extension
        .row
            .col-md-12
                p Chrome is preventing us from properly loading your app in our preview window.

                p Use the button below to preview and build your content in a new window using our Chrome extension.

                p.text-center
                    button.btn.btn-primary.btn-lg(data-action='initExtensionBuilder') Launch in a new window

                p If you notice any issues, please contact <a href="mailto:support@appcues.com">support@appcues.com</a>.

