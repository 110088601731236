extends install-guide
block stepContent
    .panel-heading.panel-heading-active.panel-heading-instructions(data-toggle='collapse', data-target='#instructionsBody', aria-expanded='false', aria-controls='instructionsBody')
        h3.panel-title
            | 2. Installing in a single-page web app
    .panel-body#instructionsBody.collapse.in
        .text-center
            p Choose your framework:
        .text-left
            .code-snippet-ig
        br
        p.btn.btn-success.pull-right#next Okay got it!
