extends modal

block header
    h4.modal-title Advanced Editing

block body
    if isPublished
        div.alert.alert-warning.text-center
            | Editing is disabled when published.&nbsp;
            b Close&nbsp;
            | and&nbsp;
            b unpublish&nbsp;
            | to make changes.
    form.form
        .form-group
            label Selector
            input.form-control(type='text', name='selector', value=selector, placeholder='body .main-content', disabled=isPublished)
            .help-block A unique CSS selector for the element you're drawing attention to.
        .form-group
            label Distance from left edge
            .input-group
                input.form-control(type='number', name='offsetXPercentage', value=offsetXPercentage, placeholder='25', disabled=isPublished)
                span.input-group-addon %
        .form-group
            label Distance from top edge
            .input-group
                input.form-control(type='number', name='offsetYPercentage', value=offsetYPercentage, placeholder='50', disabled=isPublished)
                span.input-group-addon %

        .radio-reveal
            label Tooltip position
            - var hasEdgeComboSet = tooltipAlignment != null ? true : false;
            .radio
                label
                    input(type='radio' name='_tooltipAlignment' value='false' checked=!hasEdgeComboSet disabled=isPublished)
                    | Automatically choose an optimal position
            .radio
                label
                    input(type='radio' name='_tooltipAlignment' value='true' checked=hasEdgeComboSet disabled=isPublished)
                    | Always show in the same position

            .radio-content(class=(!hasEdgeComboSet && 'hidden'))
                select.form-control(name='tooltipAlignment' disabled=isPublished)
                    for combo in edgeCombos
                        option(value=combo.value, selected=(tooltipAlignment === combo.value))!= combo.name

block footer
    if isPublished
        a.btn.btn-link(data-dismiss='modal') Close
    else
        a.btn.btn-link(data-dismiss='modal') Cancel
        button.btn.btn-primary.modal-save(type='button') Save changes
