extends modal

block header
    h4.modal-title #{title}

block body
    != content

block footer
    a(data-dismiss='modal') OK, I've got it!
