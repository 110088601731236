extends floating-cta

block body
    .media
        .media-left
            img.media-object(src='/images/trophy.png', width='64', height='64')
        .media-body
            p We're looking for customers to user test some new interfaces for us.
                strong  It's your chance to help build a great product!

            // Incentive variation
            //p We're looking for customers to user test some new interfaces for us. In exchange for 45 minutes of your time, you'll get a #[strong $25 Amazon gift card].

block actions
    p
        a.btn.btn-primary(href='https://calendly.com/appcues-ux/appcues-user-test', target='_blank') Schedule a user test
        | &nbsp;or&nbsp;
        a.dismiss no thanks
