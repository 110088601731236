.main-row
    .container
        .row
            .col-md-12
                block title
        .row
            aside.col-md-3
                .sidebar
                    block sidebar

            section.main-content.col-md-9
                block content
