extends step-action

block content
    header
        h2 What should your customer do in this step?

    section
        .row
            .text-center(class='col-sm-4')
                .well.show-step-action(data-action-type='image')
                    div: i.fa.fa-picture-o.fa-4x
                    span View a screenshot
            .text-center(class='col-sm-4')
                .well.show-step-action(data-action-type='video')
                    div: i.fa.fa-play-circle.fa-4x
                    span Watch a short video
            .text-center(class='col-sm-4')
                .well.show-step-action(data-action-type='social')
                    div: i.fa.fa-twitter.fa-4x
                    span Share on social media
        .row
            .text-center(class='col-sm-4')
                .well.show-step-action(data-action-type='form')
                    div: i.fa.fa-edit.fa-4x
                    span Fill out a form

        .help-block.text-center
            p Don't see the step you need? #[a(href="http://ideas.appcues.com" target="_blank") Let us know] to add it.

block buttons
