.radio(class=(showTargetContents ? '' : 'active'))
    label
        input(type="radio" name="toggleContents" value="all" checked=(showTargetContents ? false : true) disabled=isPublished)
        span
        | Any content history

.radio(class=(positiveMatch ? 'active' : ''))
    label
        input(type="radio" name="toggleContents" value="positiveMatch" checked=(positiveMatch ? true : false) disabled=isPublished)
        span
        | Users who have seen Appcues content

.radio(class=(negativeMatch ? 'active' : ''))
    label
        input(type="radio" name="toggleContents" value="negativeMatch" checked=(negativeMatch ? true : false) disabled=isPublished)
        span
        | Users who have not seen Appcues content

.content(class=(showTargetContents ? 'active' : ''))
    if flows.length || hotspotGroups.length || coachmarkGroups.length
        select#targetContentId.form-control(name='targetContentId' disabled=isPublished)
            option(value="" selected=(!ruleTargetContentId ? 'selected' : false))
            if flows.length
                optgroup(label="Modals")
                    each flow in flows
                        - selected = (ruleTargetContentId && (ruleTargetContentId === flow.id))
                        option(value=flow.id data-data=_.pick(flow, ['name', 'published', 'id']) selected=(selected ? 'selected' : false))= flow.name
            if hotspotGroups.length
                optgroup(label="Hotspots")
                    each hotspotGroup in hotspotGroups
                        - selected = (ruleTargetContentId && (ruleTargetContentId === hotspotGroup.id))
                        option(value=hotspotGroup.id data-data=_.pick(hotspotGroup, ['name', 'published', 'id']) selected=(selected ? 'selected' : false))= hotspotGroup.name
            if coachmarkGroups.length
                optgroup(label="Coach Marks")
                    each coachmark in coachmarkGroups
                        - selected = (ruleTargetContentId && (ruleTargetContentId === coachmark.id))
                        option(value=coachmark.id data-data=_.pick(coachmark, ['name', 'published', 'id']) selected=(selected ? 'selected' : false))= coachmark.name
        if targetContentDraftWarning
            .small.help-block Note: The selected content is not currently published.
    else
        .help-block Whoops! Looks like you don't have any content created yet. #[a(href='/create' target='_blank') Go create something!]

