- var viewCount = hotspotGroup.totalUniqueViews || 0
- var completeCount = hotspotGroup.totalUniqueCompleted || 0
- var errorCount = hotspotGroup.totalUniqueError || 0

.container
    .main-content

        //- TODO: Filter this to recent errors only.
        if errorCount > 0
            .alert.alert-warning
                p There #{s.pluralize(errorCount, 'was', 'were')} #{errorCount} #{s.pluralize(errorCount, 'occasion')} where we were unable to show hotspots. We've written #[a(href='http://docs.appcues.com/article/64-how-to-update-misplaced-hotspots') a detailed explanation] of why this happens and how to fix it.

        .row
            .col-xs-6.text-center
                .well
                    h3= s.numberFormat(viewCount)
                    h5 #{s.pluralize(viewCount, 'User')} Reached
            .col-xs-6.text-center
                .well
                    h3= s.numberFormat(completeCount)
                    h5 #{s.pluralize(completeCount, 'User')} Completed

        .row
            .col-sm-12
                .panel.panel-default
                    .panel-heading
                        .row
                            .col-sm-8
                                h5 Users Reached
                            .col-sm-4
                                input.form-control(name="searchUsers" placeholder="Search")
                    if loading
                        .panel-body.text-center
                            br
                            i.fa.fa-circle-o-notch.fa-spin.fa-lg.text-muted
                            br
                            br
                    else if tableData && tableData.length
                        table.table.table-striped.table-bordered
                            thead
                                tr
                                    th
                                        | User
                                    th
                                        | Hotspots Activated
                                    th
                                        | Completed

                            tbody
                                for row in tableData
                                    tr
                                        td= row.userDisplay || row.userId
                                        td= row.hotspotsActivated
                                        td(data-value=(row.completedCount > 0))
                                            if row.completedCount
                                                | Yes
                                                if row.completedCount > 1
                                                    span.text-muted.pull-right.hint--right(data-hint="Completed #{row.completedCount} times")
                                                        i.fa.fa-asterisk
                                            else
                                                | No
                    else
                        .panel-body.text-center
                            p No users reached yet.
