.appcues-preview-frame-message-container
    .appcues-preview-frame-message.container
        .row
            .col-md-12
                h2 Set your target URL
        .row
            .col-md-12
                p Chrome is preventing us from properly loading your app in our preview window.

                p Enter the URL of the page in your app where you want to build content, then press &ldquo;Continue&rdquo; to get started.

                p
                    .input-group
                        input.form-control(type='text' name='previewUrl' value=previewUrl)
                        span.input-group-btn
                            a.btn.btn-primary(href=designStepUrl) Continue

                p If you notice any issues, please contact <a href="mailto:support@appcues.com">support@appcues.com</a>.

