.container
    .row
        .col-md-12
            .panel.panel-default
                .panel-body.text-center
                    h2 Finally, smarter onboarding.
                    p We're currently still working on this dashboard, but it'll be up soon.

                    a.btn.btn-primary(href='/flows/create') Create an onboarding flow
    .row
        .col-md-12
            .row
                .col-md-4
                    .panel.panel-default.stat-panel.stat-panel-danger
                        .panel-body
                            h3 0
                            p unfinished flows
                .col-md-4
                    .panel.panel-default.stat-panel.stat-panel-warning
                        .panel-body
                            h3 #{unpublished}
                            p unpublished flows

                .col-md-4
                    .panel.panel-default.stat-panel.stat-panel-success
                        .panel-body
                            h3 #{published}
                            p published flows
