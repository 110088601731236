div
    .btn-group-vertical.btn-block(data-toggle='buttons')
        label.btn.btn-block(class=(required ? 'btn-default' : 'btn-primary'))
            | Optional
            input(type='radio' name='required' value='false')
        label.btn.btn-block(class=(required ? 'btn-primary' : 'btn-default'))
            | Required
            input(type='radio' name='required' value='true')
if fieldType == 'text'
    hr(style="margin: 5px 0")
    div
        .btn-group-vertical.btn-block(data-toggle='buttons')
            label.btn.btn-block(class=(['email', 'url', 'number'].indexOf(validationType) == -1 ? 'btn-primary' : 'btn-default'))
                | Text
                input(type='radio' name='validationType' value='text')
            label.btn.btn-block(class=(validationType == 'email' ? 'btn-primary' : 'btn-default'))
                | Email
                input(type='radio' name='validationType' value='email')
            label.btn.btn-block(class=(validationType == 'url' ? 'btn-primary' : 'btn-default'))
                | URL
                input(type='radio' name='validationType' value='url')
            label.btn.btn-block(class=(validationType == 'number' ? 'btn-primary' : 'btn-default'))
                | Number
                input(type='radio' name='validationType' value='number')
else if fieldType == 'rating'
    hr(style="margin: 5px 0")
    div
        .btn-group-vertical.btn-block(data-toggle='buttons')
            label.btn.btn-block(class=(ratingType == '1-5' ? 'btn-default' : 'btn-primary'))
                | 0-10
                input(type='radio' name='ratingType' value='0-10')
            label.btn.btn-block(class=(ratingType == '1-5' ? 'btn-primary' : 'btn-default'))
                | 1-5
                input(type='radio' name='ratingType' value='1-5')
