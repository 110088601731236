.container
    .row
        section.col-md-8.col-md-offset-2
            br
            br
            .panel.panel-default
                .panel-body
                    .text-center
                        h1 Installation Instructions

                        p(style='font-size: 120%;') Installing Appcues in your app is quick and commitment-free.

                    div.
                        <script charset="ISO-8859-1" src="//fast.wistia.com/assets/external/E-v1.js" async></script><div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><div class="wistia_embed wistia_async_a1qx1q9i48 videoFoam=true" style="height:100%;width:100%">&nbsp;</div></div></div>
                    br
                    .code-snippet

                    p.text-center(style='font-size: 120%;') We are here to help! #[a(href='mailto:support@appcues.com?subject=I\'d like help with installation') Send us an email] with any questions.

            p.text-center.text-muted Let's make great user experiences together.
