mixin contentRow(content)
    if content.patternType === "shorty"
        - var imgUrl = "/images/content-list/slide-out.png"
        - var designUrl = "/flows/" + content.id + "/analytics"
    else if content.patternType == "modal"
        - var imgUrl = "/images/content-list/flow-single.png"
        - var designUrl = "/flows/" + content.id + "/analytics"
    else if content.sequential
        - var imgUrl = "/images/content-list/tooltips-single.png"
        - var designUrl = "/tooltips/" + content.id + "/analytics"
    else
        - var imgUrl = "/images/content-list/hotspots-single.png"
        - var designUrl = "/hotspots/" + content.id + "/analytics"

    tr
        td(style="width:50px")
            img(src=imgUrl, width="32", height="32")
        td(style="line-height:32px")
            | #{content.name}
        td(style="line-height:32px")
            if content.published
                | Live
            else
                | Draft
        td(style="line-height:32px")
            a.pull-right(href=designUrl target="_blank") View


.modal-dialog
    .modal-content.content-list-modal
        .modal-header
            button.close(type='button', data-dismiss='modal', aria-hidden='true') &times;
            block header
                h4.modal-title #{title}

        .modal-body
            block body
            table.table
                tbody
                    each flow in contentList
                        +contentRow(flow)
