.main-row.build-content
    .container
        .row
            a.build-bar-setting(class=(stepsOpen ? 'open' : 'closed') data-action='toggleFlowSteps')
                | #{stepCount} step#{stepCount == 1 ? '' : 's'}

            .btn-group.build-bar-dropdown
                a.build-bar-setting.closed(data-setting="pattern")
                    if flow.patternType === 'modal'
                        | Modal&nbsp;
                    else if flow.patternType === 'shorty'
                        | Slideout&nbsp;
                    else if flow.patternType === 'left'
                        | Sidebar&nbsp;
                    else if flow.patternType === 'fullscreen'
                        | Full Screen&nbsp;
                .build-bar-dropdown-content
                        label(class=[(rule.published ? 'disabled' : ''), ((flow.position == 'topLeft') ? 'selected' : '')])
                            input(type='radio' name='patternType' checked=(flow.patternType == 'modal') value='modal' data-display="Modal" disabled=rule.published)
                            .option-label Modal
                            .option-description Pops up the content in the middle of the screen with your app masked behind it.
                        label(class=[(rule.published ? 'disabled' : ''), ((flow.position == 'topLeft') ? 'selected' : '')])
                            input(type='radio' name='patternType' checked=(flow.patternType == 'shorty') value='shorty' data-display="Slideout" disabled=rule.published)
                            .option-label Slideout
                                sup &nbsp;New!
                            .option-description An unintrusive popout that can be positioned anywhere in your app.
                        label(class=[(rule.published ? 'disabled' : ''), ((flow.position == 'topLeft') ? 'selected' : '')])
                            input(type='radio' name='patternType' checked=(flow.patternType == 'left') value='left' data-display="Sidebar" disabled=rule.published)
                            .option-label Sidebar
                            .option-description Slides the content out from the side your app visible in the background.
                        label(class=[(rule.published ? 'disabled' : ''), ((flow.position == 'topLeft') ? 'selected' : '')])
                            input(type='radio' name='patternType' checked=(flow.patternType == 'fullscreen') value='fullscreen' data-display="Full Screen" disabled=rule.published)
                            .option-label Full Screen
                            .option-description Blocks out your app and shows the content over a solid gray background.

            .btn-group.build-bar-dropdown
                a.build-bar-setting.closed(data-setting="skippable")
                    if flow.skippable
                        | Skippable&nbsp;
                    else
                        | Required&nbsp;
                .build-bar-dropdown-content
                    label(class=[(rule.published ? 'disabled' : ''), ((flow.position == 'topLeft') ? 'selected' : '')])
                        input(type='radio' name='skippable' checked=flow.skippable value='true' data-display="Skippable" disabled=rule.published)
                        .option-label Skippable
                        .option-description Adds a close button to the top-right corner to let users skip the flow.
                    label(class=[(rule.published ? 'disabled' : ''), ((flow.position == 'topLeft') ? 'selected' : '')])
                        input(type='radio' name='skippable' checked=!flow.skippable value='false' data-display="Required" disabled=rule.published)
                        .option-label Required
                        .option-description Hides the close button and forces users to go through each step before closing.

            if flow.patternType === 'shorty'
                .btn-group.build-bar-dropdown
                    a.build-bar-setting.closed(data-setting="position")
                        case flow.position
                            when 'topLeft'
                                | Top Left&nbsp;
                            when 'left'
                                | Left&nbsp;
                            when 'bottomLeft'
                                | Bottom Left&nbsp;
                            when 'center'
                                | Center&nbsp;
                            when 'bottom'
                                | Bottom&nbsp;
                            when 'topRight'
                                | Top Right&nbsp;
                            when 'right'
                                | Right&nbsp;
                            when 'bottomRight'
                                | Bottom Right&nbsp;
                            when 'top'
                            default
                                | Top&nbsp;
                    .build-bar-dropdown-content.grid
                        div.window-grid
                            div.column
                                label.square(class=[(rule.published ? 'disabled' : ''), ((flow.position == 'topLeft') ? 'selected' : '')])
                                    input(type='radio' name='position' checked=(flow.position == 'topLeft') value='topLeft' data-display='Top Left' disabled=rule.published)
                                    i.fa-check
                                label.square(class=[(rule.published ? 'disabled' : ''), ((flow.position == 'left') ? 'selected' : '')])
                                    input(type='radio' name='position' checked=(flow.position == 'left') value='left' data-display='Left' disabled=rule.published)
                                    i.fa-check
                                label.square(class=[(rule.published ? 'disabled' : ''), ((flow.position == 'bottomLeft') ? 'selected' : '')])
                                    input(type='radio' name='position' checked=(flow.position == 'bottomLeft') value='bottomLeft' data-display='Bottom Left' disabled=rule.published)
                                    i.fa-check
                            div.column
                                label.square(class=[(rule.published ? 'disabled' : ''), ((flow.position == 'top') ? 'selected' : '')])
                                    input(type='radio' name='position' checked=(flow.position == 'top') value='top' data-display='Top' disabled=rule.published)
                                    i.fa-check
                                label.square(class=[(rule.published ? 'disabled' : ''), ((flow.position == 'center') ? 'selected' : '')])
                                    input(type='radio' name='position' checked=(flow.position == 'center') value='center' data-display='Center' disabled=rule.published)
                                    i.fa-check
                                label.square(class=[(rule.published ? 'disabled' : ''), ((flow.position == 'bottom') ? 'selected' : '')])
                                    input(type='radio' name='position' checked=(flow.position == 'bottom') value='bottom' data-display='Bottom' disabled=rule.published)
                                    i.fa-check
                            div.column
                                label.square(class=[(rule.published ? 'disabled' : ''), ((flow.position == 'topRight') ? 'selected' : '')])
                                    input(type='radio' name='position' checked=(flow.position == 'topRight') value='topRight' data-display='Top Right' disabled=rule.published)
                                    i.fa-check
                                label.square(class=[(rule.published ? 'disabled' : ''), ((flow.position == 'right') ? 'selected' : '')])
                                    input(type='radio' name='position' checked=(flow.position == 'right') value='right' data-display='Right' disabled=rule.published)
                                    i.fa-check
                                label.square(class=[(rule.published ? 'disabled' : ''), ((flow.position == 'bottomRight') ? 'selected' : '')])
                                    input(type='radio' name='position' checked=(flow.position == 'bottomRight') value='bottomRight' data-display='Bottom Right' disabled=rule.published)
                                    i.fa-check
                .btn-group
                    - var lightsAction = (rule.published) ? "" : "toggleBackdrop"
                    a.build-bar-setting(data-setting="backdrop", data-action=lightsAction)
                        if flow.backdrop
                            | Lights Off
                        else
                            | Lights On

                .btn-group
                    .build-bar-setting.disabled
                        if flow.backdrop
                            | No Transition
                        else
                            case flow.position
                                when 'top'
                                    i.fa.fa-angle-down
                                    | &nbsp;Slides from Top
                                when 'topLeft'
                                when 'bottomLeft'
                                    i.fa.fa-angle-right
                                    | &nbsp;Slides from Left
                                when 'bottom'
                                    i.fa.fa-angle-up
                                    | &nbsp;Slides from Bottom
                                when 'topRight'
                                when 'bottomRight'
                                    i.fa.fa-angle-left
                                    | &nbsp;Slides from Right
                                default
                                    | Fades In

            if flowStyle
                .btn-group.build-bar-dropdown
                    a.build-bar-setting.closed(data-setting="style")
                        | #{flowStyle.name}
                    .build-bar-dropdown-content
                        each style in styles
                            label(class=[(rule.published ? 'disabled' : ''), ((flowStyle && flowStyle.id == style.id) ? 'selected' : '')])
                                input(type='radio' name='style' checked=(flowStyle && flowStyle.id == style.id) value=style.id data-display=style.name disabled=rule.published)
                                .option-label #{style.name}

#build-flow-steps(class=(stepsOpen ? 'open' : 'closed'))
    .container

