div.timeframe
    .alert.alert-danger.hidden(role='alert')

    if timeframeType == 'start'
        .form-group
            label.control-label(for='date') Start:
            input.form-control.timeframe-field(name='date' Label='Date' value=date placeholder="e.g. 2am march 14")

    if timeframeType == 'end'
        .form-group
            label.control-label(for='date') End:
            input.form-control.timeframe-field(name='date' Label='Date' value=date placeholder="e.g. next friday at noon")

    .small.help-block These rules are specific to your timezone, not your users'.