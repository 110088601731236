include content-mixins
- var settingsUrl = '/coachmarks/' + model.id + '/settings'
- var isArchived = model.state === 'ARCHIVED'

td.icon
    a(href="/coachmarks/#{model.id}/design")
        if model.hotspots && _.size(model.hotspots) === 1
            img(src="/images/content-list/coachmarks-single.png", width="64", height="64")
        else
            img(src="/images/content-list/coachmarks-multiple.png", width="64", height="64")

td.details
    +contentDetails(model, user, createdBy, lastEditedBy, "/coachmarks/" + model.id + "/design")

td.target.text-left.hidden-sm
    +pageTargeting(settingsUrl, rule)

td.stats.text-left
    +contentStats(model, stats)

td.target.text-left
    a(href=settingsUrl)
        +userTargeting(isUserTargeted, targetedBy, rule)

unless isArchived
    td.status.text-left
        a(href=settingsUrl)
            +contentState(model, isInstalled)

td.action
    a.on-hover.hint--bottom(href="/coachmarks/#{model.id}/settings" data-hint="Settings"): i.fa.fa-fw.fa-gear

td.action
    a.on-hover.hint--bottom(href="/coachmarks/#{model.id}/analytics" data-hint="View Reporting"): i.fa.fa-fw.fa-bar-chart-o

td.action
    a.on-hover.hint--bottom(data-action='clone' data-hint="Clone Coach Marks"): i.fa.fa-fw.fa-copy

td.action
    if isArchived
        a.on-hover.hint--bottom(data-action='delete' data-hint="Delete Coach Marks"): i.fa.fa-fw.fa-trash
    else
        a.on-hover.hint--bottom(data-action='archive' data-hint="Archive Coach Marks"): i.fa.fa-fw.fa-times

if isArchived
    td.action
        a.on-hover.hint--bottom(data-action='unarchive' data-hint="Restore Coach Marks"): i.fa.fa-fw.fa-upload
