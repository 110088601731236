ul.thumbnails-list.thumbnails-list-sequence.list-group.list-inline
    if steps
        each step in steps
            - var isSelected = step.id == selectedId
            li.thumbnail-item.list-group-item.draggable-list-group-item.sequential.text-center(class=(isSelected ? 'selected ' : '') data-id=step.id)
                img(src="/images/steps/#{step.stepType || 'default'}.png", width='70', height='70')

                .thumbnail-controls
                    a.step-info(data-action='showStepInfo' data-step-id=step.id)
                        if isEditable
                            i.fa.fa-fw.fa-info
                        else
                            i.fa.fa-fw.fa-eye

                    if steps.length > 1
                        if isEditable
                            a.delete-step(data-action='deleteStep')
                                i.fa.fa-fw.fa-trash

    if isEditable
        li.thumbnail-item.list-group-item.dashed.add-step.text-center
            img(src="/images/steps/plus.png", width='70', height='70')
