extends step-action

block content
    .components

    if isEditable
        .empty-state.appcues-style
            img(src='/images/components-empty.jpg' data-action='showComponentTypes')

        .add-component.appcues-style
            .choose-component-types
                .component-types
                    .row
                        .col-sm-3
                            a(data-action='addComponent' data-component-type='editor')
                                i.fa.fa-lg.fa-paragraph
                                | Add text
                        .col-sm-3
                            a(data-action='addComponent' data-component-type='image')
                                i.fa.fa-lg.fa-image
                                | Add an image
                        .col-sm-3
                            a(data-action='addComponent' data-component-type='hero')
                                i.fa.fa-lg.fa-image
                                | Add a hero
                        .col-sm-3
                            a(data-action='addComponent' data-component-type='video')
                                i.fa.fa-lg.fa-film
                                | Add a video
                    .row
                        .col-sm-3
                            a(data-action='addComponent' data-component-type='form')
                                i.fa.fa-lg.fa-check-square-o
                                | Add a form
            span.add-icon.hint--right(data-action='showComponentTypes' data-hint="Add content")
                i.fa.fa-plus
