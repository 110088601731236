.modal-dialog
    .modal-content
        .modal-header
            a.close.hint--top(data-action="close" data-hint="Close") &times;

            if installed
                h4.modal-title You successfully installed the Appcues Chrome Extension!
                p Our Chrome Extension makes building flows directly on your website easy.
                p Enter your website URL, then click Next.
            else
                h4.modal-title Where do you want to create your Appcues flows?
                p Select or enter a website URL to start building.

        .modal-body
            .form-group
                select.form-control(name='chooseDomain' placeholder="www.example.com")

        .modal-footer
            a.btn.btn-success.pull-right(data-action="done") Next
