extends step-action

block content
    if isEditable
        textarea.redactor
            if content
                != content
            else
                img(src='/images/placeholders/image-placeholder.jpg', width='100%')

                hr

                h4(style='text-align: center') [Insert your headline]
                p(style='text-align: center')
                    | Put a brief description of your product here. You can use HTML formatting just like you would in any other word processor. You can even insert dynamic user variables by surrounding them with brackets, like
                    strong  {{first_name}}
                    | . Give it a try!

    else
        != content
