.modal-dialog
    .modal-content
        .modal-header
            a.close(data-dismiss="modal") &times;
            h4.modal-title Manage Your Categories

        .modal-body
            form.form-horizontal
                for category in categories
                    .form-group
                        .col-sm-11
                            input.form-control.category-name(name='categoryName' value=category.name defaultValue=category.name id=category.id placeholder='Enter a name...')
                        .col-sm-1
                            a.btn.btn-link.remove-category.hint--left(data-action="removeCategory" data-id=category.id data-hint='Remove this category' style="margin-left: -1em")
                                i.fa.fa-fw.fa-lg.fa-times
            div
                a.btn.btn-success.newCategory(data-action="newCategory") Add Category

        .modal-footer
            a.btn.btn-primary.pull-right(data-action="done") Done
