extends example-teaser

block header

block screenshot
    img(src='/images/teasers/flow-analytics.png', width="100%")

block description
    h3 Appcues for Experiments
    hr

block thread-placeholder
    p: strong Jonathan Kim, CEO @ Appcues
    p &#x1f44b; Hi! We're building a new suite of features to help you improve your user activation rate. Is this something you're already doing?
    p We currently have #[b goal tracking] and #[b A/B testing] on our minds.
    p What are we missing?