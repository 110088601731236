.container
    if !hideTitle
        if hasAuth && !isInstalled
            .notice.alert-warning.text-center
                p
                    b Looks like you're not installed!
                    | &nbsp;Before you can view your flows live on your site, you'll need to install Appcues on your site.&nbsp;
        .text-center
            h2(style="padding-bottom: 10px;") Installation Guide
            p(style="padding-bottom: 10px;") Installing Appcues in your app is quick, commitment-free and can be removed at any time.

    .panel.panel-default
        .panel-heading.panel-choose-install.panel-heading-active.panel-heading-instructions(data-toggle='collapse', data-target='#installChoice', aria-expanded='false', aria-controls='installChoice')
            h3.panel-title
                if this.installType
                    | 1. Install method: #[strong(class='text-success') #{this.installType}] (Click to change)
                else
                    | 1. Choose an install option
        .panel-body#installChoice.collapse.in
            .text-left
                table.table-padded
                    tbody
                        tr
                            td(style="width:50%;")
                                a.btn.btn-outline.btn-outline-primary.btn-block.choose-install-type(href='/install/traditional#{accountId ? "/" + accountId : ""}') Basic Javascript
                            td Our traditional way of installing
                        tr
                            td
                                a.btn.btn-outline.btn-outline-primary.btn-block.choose-install-type(href='/install/spa#{accountId ? "/" + accountId : ""}') Single-page web app
                            td Installing with a front-end framework such as Angular, Ember, or React
                        tr
                            td
                                a.btn.btn-outline.btn-outline-primary.btn-block.choose-install-type(href='/install/segment#{accountId ? "/" + accountId : ""}') Segment
                            td One-click installation with Segment
            .text-center
                p(style="margin-bottom: 10px;") Can't find what you're looking for? Check out our #[a(href='http://docs.appcues.com/category/12-installation', target='_blank') installation docs].
    .panel.panel-default
        block stepContent
            //- Default content
            .panel-heading
                h3.panel-title
                    | 2. View instructions for selected option above

    .panel.panel-default#verify
        .panel-heading.panel-heading-verify(data-toggle='collapse', data-target='#verifyInstall', aria-expanded='false', aria-controls='#verifyInstall')
            h3.panel-title
                | 3. Verify your installation
        .panel-body#verifyInstall.collapse.in.text-center
            if hasAuth
                if isInstalled
                    .notice.alert-success(style='margin-bottom: 15px; margin-top: 0;')
                        p
                            b Great! We can detect Appcues on your app.

                else
                    .notice.alert-danger(style='margin-bottom: 15px; margin-top: 0;')
                        p
                            b Looks like you're not installed!
                            | &nbsp; If your need help with installation, feel free to #[a(href='mailto:support@appcues.com?subject=Can you help me get installed?') reach out] to us.&nbsp;
            .div.text-left
                ul
                    li
                        p #[strong Review] your installation by appending #[code ?hey_appcues] to the end of any page that has your script installed.
                    li
                        p You should see a panel on the bottom left with information about your installation status. #[strong Click around your site] to verify Appcues is picking up your navigation and user properties correctly.
                    li
                        p #[strong Test] your data by publishing a sample flow, targeted to just you. Check for #[a(href='http://docs.appcues.com/article/47-how-to-send-user-properties-to-appcues', target='_blank') user properties] and #[a(href='http://docs.appcues.com/article/156-event-targeting', target='_blank') custom events] that you are sending with the Appcues install.
                p.alert.alert-warning(style="margin-bottom:0;")
                    | You can find more information in our #[a(href='http://docs.appcues.com/article/34-debugging-your-appcues-installation', target='_blank') install verification guide].
                    | &nbsp; Questions about your install? #[a(href='mailto:support@appcues.com?subject=Can you check my installation?') Contact us].

    p.text-center.text-muted(style='margin-top: 25px;margin-bottom: 25px;') We are here to help! #[a(href='mailto:support@appcues.com?subject=I want help with installation') Send us an email] with any questions.
