mixin jsSnippet(meta)
    - var appcuesId = meta.accountId || '<YOUR_APPCUES_ID>'
    pre(style='display:flex; flex-direction:row; align-items:flex-start')
        code.language-javascript(style='flex-grow:1; align-self:center; overflow:scroll')
              = '<script src="//' + meta.cdnDomain + '/' + appcuesId + '.js"></script>'
        button.btn.btn-primary.btn-sm.copy Copy

mixin identifySnippet(platform, meta)
    pre(style='display:flex; flex-direction:row; align-items:flex-start')
        code.language-javascript(style='flex-grow:1; overflow:scroll')
            if platform.id == 'generic'
                = "// TODO: Replace the placeholders below with values for the current user.\n    // The installation will not work until this is done."
            if platform.id == 'angular'
                = "// consider we have a login controller:\n.controller('LoginController', function($scope, $rootScope, AUTH_EVENTS, AuthService) {\n  $scope.credentials = {\n    username: '',\n    password: ''\n  };\n  $scope.login = function (credentials) {\n    AuthService.login(credentials).then(function (user) {\n      $rootScope.$broadcast(AUTH_EVENTS.loginSuccess);\n      $scope.setCurrentUser(user);\n      // Call Appcues.identify()\n\n      Appcues.identify(currentUser.id, {\n        name: currentUser.name,\n        email: currentUser.email,\n        created_at: currentUser.createdAt\n        // Additional user properties.\n      });\n    }, function () {\n      $rootScope.$broadcast(AUTH_EVENTS.loginFailed);\n    });\n  };\n})"
                return
            if platform.id == 'traditional'
                = "// NOTE: These values should be specific to the current user.\n<script>\n  Appcues.identify(" + platform.userId + ", { // Replace with unique identifier for current user\n    name: " + platform.userName + ",   // Current user's name\n    email: " + platform.userEmail + ", // Current user's email\n    created_at: " + platform.createdAt + ",    // Unix timestamp of user signup date\n\n    // Additional user properties.\n    // is_trial: " + platform.isTrial + ",\n    // plan: " + platform.plan + "\n\n  });\n</script>"
            else
                = "// NOTE: These values should be specific to the current user.\n  Appcues.identify(" + platform.userId + ", { // Replace with unique identifier for current user\n    name: " + platform.userName + ",   // Current user's name\n    email: " + platform.userEmail + ", // Current user's email\n    created_at: " + platform.createdAt + ",    // Unix timestamp of user signup date\n\n    // Additional user properties.\n    // is_trial: " + platform.isTrial + ",\n    // plan: " + platform.plan + "\n\n  });\n"
        button.btn.btn-primary.btn-sm.copy Copy

mixin routerSnippet(platform, meta)
    pre(style='display:flex; flex-direction:row; align-items:flex-start')
        code.language-javascript(style='flex-grow:1; overflow:scroll')
            if selectedPartner.id == 'react'
                = "// Example 1:\n  <Route path='/' component={App} onChange={()=>window.Appcues.start()}>\n\n// Example 2:\n  <Router onUpdate={()=>window.Appcues.start()}/>\n\n// Note: These examples apply to react-router v3 and below. For v4, refer to the suggestions here\n// https://github.com/ReactTraining/react-router/issues/4278"
            if selectedPartner.id == 'ember'
                = "// Ember instructions: in your router.js:\n  Router.reopen({\n     checkForAppcues: function () {\n        Ember.run.scheduleOnce('afterRender', function() {\n            Appcues.start();\n        });\n     }.on('didTransition')\n   });"
            if selectedPartner.id == 'angular'
                = "// For example, in your App.js:\n  angular.module('yourapp').run(function($rootScope, $window) {\n   $rootScope.$on('$locationChangeSuccess', function() {\n       if ($window.Appcues) {\n          $window.Appcues.start();\n        }\n     });\n   });"
            if selectedPartner.id == 'other'
                = "// Place an Appcues.start() call somewhere in your router\n// after it has recognized that the location has been successfully updated"
        button.btn.btn-primary.btn-sm.copy Copy

mixin identifySnippetSPA(platform, meta)
    pre(style='display:flex; flex-direction:row; align-items:flex-start')
        code.language-javascript(style='flex-grow:1; overflow:scroll')
            if selectedPartner.id == 'other'
                = "// If you are using a framework not listed above, please don't hesitate to reach out to us!"
            else
                if selectedPartner.id == 'angular'
                    = "// consider we have a login controller:\n.controller('LoginController'), function($scope, $rootScope, AUTH_EVENTS, AuthService) {\n  $scope.credentials = {\n    username: '',\n    password: ''\n  };\n  $scope.login = function (credentials) {\n    AuthService.login(credentials).then(function (user) {\n      $rootScope.$broadcast(AUTH_EVENTS.loginSuccess);\n      $scope.setCurrentUser(user);\n      // Call Appcues.identify()\n      Appcues.identify(currentUser.id, {\n        name: currentUser.name,\n        email: currentUser.email,\n        created_at: currentUser.createdAt\n        // Additional user properties.\n      });\n    }, function () {\n      $rootScope.$broadcast(AUTH_EVENTS.loginFailed);\n    });\n  };\n})"
                if selectedPartner.id == 'ember'
                    = "// place your identify call wherever the router is defined, and somewhere after user authentication\n"
                    = "// NOTE: These values should be specific to the current user.\n    Appcues.identify(" + platform.userId + ", { // Replace with unique identifier for current user\n        name: " + platform.userName + ", // Current user's name\n        email: " + platform.userEmail + ", // Current user's email\n        created_at: " + platform.createdAt + ", // Unix timestamp of user signup date\n\n        // Additional user properties.\n        // is_trial: " + platform.isTrial + ",\n        // plan: " + platform.plan + "\n    });"
                if selectedPartner.id == 'react'
                    = "// place your identify call wherever the router is defined (likely 'main.js'), and somewhere after user authentication\n"
                    = "// NOTE: These values should be specific to the current user.\n    Appcues.identify(" + platform.userId + ", { // Replace with unique identifier for current user\n        name: " + platform.userName + ", // Current user's name\n        email: " + platform.userEmail + ", // Current user's email\n        created_at: " + platform.createdAt + ", // Unix timestamp of user signup date\n\n        // Additional user properties.\n        // is_trial: " + platform.isTrial + ",\n        // plan: " + platform.plan + "\n    });"
                if selectedPartner.id == 'other'
                    = "// If you are using a framework not listed above, please don't hesitate to reach out to us!"
        button.btn.btn-primary.btn-sm.copy Copy
