.bigbody
.registration-container
    .auth-page.text-center

        style.
            #hs-beacon {
                display: none!important;
            }

        .logo-header
            a(href='http://www.appcues.com')
                img.logo(src='/images/appcues-logo.svg', height='40')

        if !wasInvited
            a.well.well-sm.text-center.google-auth
                .g-logo
                    img(src='/images/google-g-logo.png')
                span Continue with Google

            .divider
                | OR

        .panel.panel-default
            .panel-body.auth-panel
                if showRedirectBackdrop
                    .redirect-backdrop
                    .redirect
                        h2 You'll be redirected in just a moment.
                        p.reset-login (Oops, I changed my mind, let's go back.)

                block content
                    - var formClass = forgotPassword ? 'forgot-password auth-form' : 'login-form auth-form'
                    form(class=formClass, role='form')
                        .form-group
                            label(for='email') Work email
                            input.form-control(name='email', type='email', placeholder='Email', autofocus='autofocus', value=email)

                        if forgotPassword
                            button.btn.btn-block.btn-primary(type='submit') Send me a new password
                        else
                            .form-group
                                label(for='password') Password
                                input.form-control(name='password', type='password', placeholder='Password')

                            .checkbox
                                label
                                    input(name='rememberMe', type='checkbox', checked='true')
                                    |  Remember me

                            button.btn.btn-block.btn-primary(type='submit') Sign in to your account

        .validation-error.hidden
            block validation
                .alert.alert-warning
                    strong Whoops!
                    |  We had an error logging you in. Check that everything looks good and try again.

        if forgotPassword
            if error
                .alert.alert-warning We were unable to reset your password. Are you sure you signed up?
            else if success
                .alert.alert-success Woot! Your new password is on it's way.

            p: a.toggle-form(data-forgot='false') Actually, I remember my password now.
        else
            div
                a.text-center.login-text-cta.toggle-form(data-forgot='true')
                    | Forgot your password?
                    strong  Send yourself a new one.

        block promo
            div
                a.text-center.login-text-cta(href="/register")
                    | Don't have an account?
                    strong  Get yours now.

