extends step-action

block content
    form.step-action-form(data-form-id=formId)
        if isEditable
            textarea.redactor.small
                if description
                    != description
                else
                    h4(style='text-align: center') [Insert your headline]
                    p(style='text-align: center')
                        | Here's where you should provide a compelling reason for someone to fill out your form. Do they get something for free? Are your questions really fun to answer?

            div.fields-container
        else
            != description

            div.fields-container
