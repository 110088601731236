- var hasHave = singular ? 'has' : 'have'

img(src="/images/close.gif", width="300", height="168")
h4 Almost there...

p This #{niceContentName} #{hasHave} been staged for publishing until you've installed the Appcues SDK. Once the SDK is installed, your users can instantly see your published content.
br
p
    a.btn.btn-primary(href="/install/a/#{accountId}" target="_blank") Install the Appcues SDK
    .text-muted (takes less than 15 minutes)
