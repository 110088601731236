.ideas-inspiration
    .row
        .col-md-6.col-md-offset-3
            .quickstart-page.text-center
                a(href='http://www.appcues.com')
                    img.logo(src='/images/logo-white.png', height='40')
        .row
            .col-md-12.text-center
                .sub-header 
                    p Discover the different use cases for in-app messaging and how you can achieve your goals using Appcues.

    .ideas-inspiration-content

        each val, index in examples
            .row
                .article-content.text-center
                    h3= val.title
                    p= val.blurb
                    a.blog-link(href=val.link, data-article=index, target="_blank") Read more...
                    .slideshow
                        .button-container.left-button(data-button='left', data-article=index)
                            button
                                i.fa.fa-angle-left
                        .img-container
                            img.slideshow-item(data-total=val.pics.length, data-index=0, data-imgArticle=index, src=val.pics[0])
                        .button-container.right-button(data-button='right', data-article=index)
                            button
                                i.fa.fa-angle-right
                    p Ready to create your own?
                        .cta-button-container
                            button.apc-button.quickstart-menu(data-article=index) Yes, let's go!
                    hr
