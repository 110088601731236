.container
    if hasAuth && !isInstalled
        .notice.alert-warning.text-center
            p
                b Looks like you're not installed!
                | &nbsp;Before you can view your flows live on your site, you'll need to install Appcues on your site.&nbsp;
    .install-container.col-md-12
        h2 Installation Guide

        if hasAuth
            .flex-between
                .select-template-button-option.text-center(class=(isOptionSelected && isInstallGuide ? 'optionSelected' : ''), data-action="isDeveloperOption")
                    .option-section-left
                        i.fa.fa-code
                    .option-section-right
                        h3 I Want to Install Appcues Myself
                        p Get detailed instructions to install in your app.
                if !hideInviteUser
                    .select-template-button-option.text-center(class=(isOptionSelected && isInviteOption ? 'optionSelected' : ''), data-action="isInviteOption")
                        .option-section-left
                            i.fa.fa-pencil-square-o
                        .option-section-right
                            h3 I Need Help From My Devs
                            p Get pointers on how to involve your devs.
                .select-template-button-option.text-center(class=(isOptionSelected && isSegmentOption ? 'optionSelected' : ''), data-action="enableWithSegment")
                    .option-section-left
                        img(src='/images/integrations/segment2.png', style="width: 40px;margin: 0 5px")
                    .option-section-right
                        h3 I Want to Enable With Segment
                        p If you use Segment, you can install instantly.

        if isSegmentOption
            h3 Enable With Segment
            .panel-default
                p(style="margin-bottom: 30px") Segment is a third-party service that routes data from your app to many different services, and gives you central control. Appcues integrates directly with Segment to make installation a one-step process.
.guide

