extends step-action

block content
    if isEditable
        textarea.redactor
            if contentWithoutButtons
                != contentWithoutButtons
            else
                img(src='/images/placeholders/image-placeholder.jpg', width='100%')

                hr

                h4(style='text-align: center') [Insert your call to action]
                p(style='text-align: center')
                    | Write a quick sentence or two about why someone should share your content. Getting someone to share on social media is a big step, so try to convince them it's worthwhile.

        include social-buttons

    else
        != content

