.container
    .main-content
        .row
            .col-md-6.col-md-offset-3
                br
                br
                br
                .panel.panel-default
                    .panel-heading
                        | Send yourself a CSV export of your data
                    .panel-body
                        if sent && !sending
                            p
                                | We're building a CSV export of your data and will send it to
                                strong &nbsp;#{email}
                                | &nbsp;when it's ready. If you have any questions about it, just let us know!
                            p Want to send another one?

                        form
                            .input-group
                                input.form-control(name='email', value=email, type='email', placeholder='ilovedata@example.com', disabled=sending, required)
                                span.input-group-btn: button.btn.btn-primary(type='submit', disabled=sending) Send a CSV
                            .text-center
                                if sending
                                    span.text-muted &nbsp;&nbsp;&nbsp;Sending...
                                else if error
                                    span.text-danger &nbsp;&nbsp;&nbsp;Failed to send :(
                            div.notice.alert-info
                                p
                                    strong Just a heads up:&nbsp;
                                    | we recently made changes to how our CSV
                                    | exports work. It might take up to 10-15 minutes
                                    | before it arrives in your inbox.
                br
                hr
                br
        .row
            .col-md-8.col-md-offset-2
                .text-center
                    h4 Want deeper insights?

                    p Activate one of our third-party integrations to learn more about your data.

                    br

                .row.text-center
                    .col-md-4
                        .panel.panel-default: .panel-body.insights
                            i.fa.fa-4x.fa-bolt.text-muted
                            br
                            p Activate one of our many third-party integrations.

                            a.btn.btn-block.btn-outline.btn-outline-primary(href='/integrations') Activate
                    .col-md-4
                        .panel.panel-default: .panel-body.insights
                            i.fa.fa-4x.fa-book.text-muted
                            br
                            p Learn how integrations work and the data we send.

                            a.btn.btn-block.btn-outline.btn-outline-primary(href='http://docs.appcues.com/category/90-third-party-integrations') Learn
                    .col-md-4
                        .panel.panel-default: .panel-body.insights
                            i.fa.fa-4x.fa-signal.text-muted
                            br
                            p Let us teach you how to set up your reports.

                            a.btn.btn-block.btn-outline.btn-outline-primary(href="mailto:support@appcues.com?subject=Training%20request%3A%20third-party%20reporting&body=Hey%20friends.%20I've%20activated%20an%20Appcues%20third-party%20integration%20and%20want%20to%20properly%20set%20up%20my%20reports%20to%20measure%20ROI%20and%20improve%20my%20user%20flows.%20Can%20you%20help?%0A%0AThanks!", target='_blank') Train
                br

                p Here are a couple of questions our customers are able to answer by activating a third-party integration:

                ul
                    li Do prospects who see Appcues convert quicker?
                    li How effective is Appcues at driving feature adoption?
                    li Which users finished (or didn't finish) this flow?

                p #[strong Need more help?] Please #[a(onclick='HS.beacon.open()') reach out] if you want advice on how to properly measure this in your existing analytics tools.
