.main-row.build-content
    .container
        .row
            a.build-bar-setting(class=(hotspotsOpen ? 'open' : 'closed') data-action='toggleHotspots')
                | #{hotspotCount} #{hotspotType}#{hotspotCount == 1 ? '' : 's'}

            .btn-group.build-bar-dropdown
                a.build-bar-setting.closed(data-setting='skippable')
                    if hotspotGroup.skippable
                        | Skippable&nbsp;
                    else
                        | Required&nbsp;
                .build-bar-dropdown-content
                    label(class=(rule.published ? 'disabled' : ''))
                        input(type='radio' name='skippable' checked=hotspotGroup.skippable value='true' data-display='Skippable' disabled=rule.published)
                        .option-label Skippable
                        .option-description #{s(hotspotType).capitalize().value()}s will have a &ldquo;hide these tips&rdquo; button.
                    label(class=(rule.published ? 'disabled' : ''))
                        input(type='radio' name='skippable' checked=!hotspotGroup.skippable value='false' data-display='Required' disabled=rule.published)
                        .option-label Required
                        .option-description #{s(hotspotType).capitalize().value()}s will stay on the page until all have been seen.

            if hotspotsStyle
                .btn-group.build-bar-dropdown
                    a.build-bar-setting.closed(data-setting="style")
                        | #{hotspotsStyle.name}
                    .build-bar-dropdown-content
                        each style in styles
                            label(class=[(rule.published ? 'disabled' : ''), ((hotspotsStyle && hotspotsStyle.id == style.id) ? 'selected' : '')])
                                input(type='radio' name='style' checked=(hotspotsStyle && hotspotsStyle.id == style.id) value=style.id data-display=style.name disabled=rule.published)
                                .option-label #{style.name}
            
            .build-bar-setting
                a.text-muted(href='http://docs.appcues.com/article/101-annotations', target='_blank') Learn about #{hotspotType}s

            unless useEager
                .build-bar-setting.pull-right
                    a(data-action='initExtensionBuilder') Build in a new window

            unless hotspotCount
                .btn-group
                    .build-bar-setting: span.text-muted Click in the screen below to add #{hotspotType}s

#build-hotspots(class=(hotspotsOpen ? 'open' : 'closed'))
    .container


