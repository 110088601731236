extends modal

block header
    h4.modal-title Invite a user to Appcues

block body
    .invite-form.form-horizontal
        p: strong Share our installation guide

        pre(style='display:flex; flex-direction:row; align-items:flex-start')
            a(href=permalink, target='_blank', style='flex-grow:1; white-space:normal; align-self:center')= permalink
            button.btn.btn-primary.btn-sm.copy Copy

        br

        p: strong Invite via email

        if inviteSuccess
            .alert.alert-success(role='alert')
                | #[strong Success!] You've invited #[strong= name] to your Appcues account.
        else
            .alert.alert-danger.invite-form-missing-alert(role='alert', class=(isEmailValid && isNameValid ? 'hidden' : '')) You must fill in all the information below to invite a new user.
            .alert.alert-danger(role='alert', class=(inviteError ? '' : 'hidden'))
                strong Uh oh!
                | &nbsp;Looks like there was an error inviting this person to your account. Check that everything below looks good and try again. Or shoot us an email at&nbsp;
                a(href="mailto:support@appcues.com") support@appcues.com
                | &nbsp;and we can get this straightened out.
            .alert.alert-danger(role='alert', class=(emailError ? '' : 'hidden'))
                strong Oops!
                | &nbsp;Looks like there was an error sending the invitation email. Shoot us an email at&nbsp;
                a(href="mailto:support@appcues.com") support@appcues.com
                | &nbsp;and we can get this straightened out.
            .alert.alert-danger(role='alert', class=(userIsAlreadyOnTeam ? '' : 'hidden'))
                strong Oops!
                | &nbsp;Looks like #{email} is already on the team.

            p.pull-left Enter the name and email address for the user, and we'll send them an email inviting them to use this Appcues account.

        .form-group.name-field(class=(isNameValid ? '' : 'has-error'))
            label.col-sm-2.control-label Name
            .col-sm-5
                input.form-control(type='text', name='firstname', label="First name", placeholder='Marty', value=firstname, readonly=inviteSuccess)
            .col-sm-5
                input.form-control(type='text', name='lastname', label="Last name", placeholder='McFly', value=lastname, readonly=inviteSuccess)
        .form-group(class=(isEmailValid ? '' : 'has-error'))
            label.col-sm-2.control-label(for='email') Email
            .col-sm-10
                input.form-control(type='text', name='email', placeholder='marty@hillvalleyhs.edu', value=email, readonly=inviteSuccess)
        .form-group
            label.col-sm-2.control-label(for='role') Role
            .col-sm-10
                select.form-control(name='role', required)
                    unless role
                        option.text-muted(disabled, selected) -- Please select --
                    for userRole in userRoles
                        option(value=userRole, selected=userRole === role)= userRole
        .col-sm-12.alert.alert-warning(role='alert', class=(isEmailTaken ? '' : 'hidden'))
            .col-sm-9
                strong This user already has an Appcues account.
                | &nbsp;Do you want to invite them to transfer their user to this account? They'll get an email to confirm.
            .col-sm-3
                button.btn.btn-warning.transfer-user-btn(type='button') Yes, Transfer

block footer
    if inviteSuccess
        | An email has been sent to
        strong &nbsp;#{email}
        | &nbsp;with instructions to join your account.

        if inviteRequiresApproval
            | &nbsp;It may take up to 24 hours to process.

    else
        a.btn.btn-default(data-dismiss='modal') Cancel
        button.btn.btn-primary.invite-btn(type='button', class=(isEmailValid && isNameValid ? '' : 'disabled')) Invite
