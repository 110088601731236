.step-container
    style.appcues-global-styling(type="text/css") !{globalStyling}
    if backdrop
        .appcues-backdrop(data-pattern-type="#{patternType}")
    appcues(data-pattern-type="#{patternType}" data-position='#{position}' class="active cue-step-#{stepNumber}")
        -var percent = ((stepNumber + 1) / totalSteps) * 100
        // trick modal-container into thinking it is in fullscreen (backdrop=true) mode at all times.
        // since the appcues-container isn't being resized programatically, it will occupy 100% of the page
        // and `appcues` will take up 100% of it, making the user's app visible but not interactive
        case patternType
            when "shorty"
                modal-container(class="fullscreen")
                    .appcues-progress
                        .appcues-progress-bar.appcues-progress-bar-success(style="width: #{percent}%")
                    if skippable
                        .appcues-skip
                            a.skip(data-step="skip" title='Skip') &times;
            default
                .appcues-progress
                    .appcues-progress-bar.appcues-progress-bar-success(style="width: #{percent}%")
                if skippable
                    .appcues-skip
                        a.skip(data-step="skip" title='Skip') &times;