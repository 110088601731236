.radio(class=(usersOption === 'all' ? 'active' : ''))
    label
        input(type="radio" name="usersOption" value="all" checked=(usersOption === 'all') disabled=isPublished)
        span
        | All users

if segments.length > 0
    .radio(class=(usersOption === 'segment' ? 'active' : ''))
        label
            input(type="radio" name="usersOption" value="segment" checked=(usersOption === 'segment') disabled=isPublished)
            span
            | Users matching a saved segment

        div.content
            select.form-control(name='segmentId' disabled=isPublished)
                option
                for segment in segments
                    option(value=segment.id selected=(rule.segmentId === segment.id))= segment.name

            if selectedSegment
                .text-muted.small
                    if _.isEmpty(segmentProperties)
                        div
                            strong All users
                    else
                        for options, name in segmentProperties
                            div
                                strong= propertyDisplay(name)
                                | &nbsp;#{operatorDisplay(name, options)}&nbsp;
                                strong= valueDisplay(name, options)

.radio(class=(usersOption === 'properties' ? 'active' : ''))
    label
        input(type="radio" name="usersOption" value="properties" checked=(usersOption === 'properties') disabled=isPublished)
        span
        | Users with specific properties

    div.content
        form.properties
        unless isPublished
            a.btn.btn-success.btn-sm(data-action="addRuleProperty") Add filter
            | &nbsp;&nbsp;
        a.btn.btn-outline.btn-sm(data-action="saveSegment" class=(_.isEmpty(rule.properties) ? 'hidden' : '') disabled=isPublished) Save as a segment

        if !accountHasProperties
            br
            br
            p.alert.alert-warning #[strong Heads up!] You haven't installed the Appcues embed script yet. Once you do, all of #[em your] user properties will automatically appear here for you to create segments. #[a(href="/install/a/#{accountId}", target='_blank') Click here to view installation instructions].

.radio(class=(usersOption === 'me' ? 'active' : ''))
    label
        input(type="radio" name="usersOption" value="me" checked=(usersOption === 'me') disabled=isPublished)
        span
        | Just me
    div.content
        .text-muted.small
            |You must be logged in at appcues.com for this option.
