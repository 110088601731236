extends floating-cta

block profile
    .profile-info
        span John Sherer
        img.img-circle(src='https://www.gravatar.com/avatar/769907f940bd746153fbfbe99b0d34c2?s=96', width='64', height='64')
        span Account Manager

block body
    p= text

block actions
    p
        a.btn.btn-primary(href=btnLink, target='_blank')= btnText
        | &nbsp;or&nbsp;
        a.dismiss maybe later
