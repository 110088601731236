include mixins/snippets
div
    .text-center
        .btn-group
            for platform in platforms
                - var selectedClass = platform.selected ? 'active' : ''
                button.btn.btn-default.partner-button(data-partner-type='platforms', data-partner-id=platform.id, class=selectedClass)= platform.name

    br

    p
        | #[strong a]. 
        strong Include 
        |  the following code snippet just after the opening&nbsp;
        code= '<body>'
        |  tag in your 
        code= 'index.html'
        | .
    +jsSnippet(meta)

    br

    p
        | #[strong b].&nbsp;
        strong Identify your users by calling 
        code= 'Appcues.identify()'
        | &nbsp;when they login.
        | &nbsp;Any properties you send here will be automatically available for targeting
        | &nbsp;(you can read more about our identify call #[a(href='http://docs.appcues.com/article/22-appcues-start-vs-appcues-identify', target='_blank') here]). Here is one place where you might identify your users:

    +identifySnippetSPA(selectedPartner, meta)

    if selectedPartner.id == 'traditional'
        p #[strong Note:] Make sure you replace the above placeholders with values that are unique to the current user.

    br
    .panel.panel-info
        .panel-body
            h5(style="margin-top: 0")
                strong Marketing sites and Anonymous Users
            p
                | If you want to trigger Appcues content for users that aren't yet logged into your app, you can call #[code Appcues.anonymous()] instead of #[code Appcues.identify()]. We'll generate a unique ID for that user and keep track of which content they've seen.
            p(style="margin-bottom: 0")
                | You can read our docs on #[a(href='http://docs.appcues.com/article/21-using-appcues-without-logged-in-users', target='_blank') how to use Appcues on a public site].
    
    p
        | #[strong c].&nbsp;
        strong Call 
        code= 'Appcues.start()'
        | &nbsp;in your application (crucial).
        
    +routerSnippet(selectedFramework, meta)

    br
    p
        | #[strong d].&nbsp;
        strong Track user behavior by calling 
        code= 'Appcues.track()'
        | &nbsp;throughout your app. (optional)
        br
        | You'll be able to target users based on events completed or not completed. Appcues will automatically capture the event completion time.
        | &nbsp;Learn more about our track call #[a(href='http://docs.appcues.com/article/156-event-targeting', target='_blank') here].
