.field-label
    if isEditable
        input.label-value(placeholder="Add label..." value=label)
        .field-actions
            a.field-action.field-drag-handle.hint--left.control-link(data-hint="Drag and drop to reorder fields")
                i.fa.fa-sort
            .btn-group
                a.field-action.field-options.hint--left.control-link(data-hint="Field options" role="button" tabindex="-1")
                    i.fa.fa-cog
            a.field-action.hint--left.control-link(data-action="removeField" data-hint="Delete this field")
                i.fa.fa-trash-o

    else
        label.label-display(for=id) #{label}

.field-controls
    if ratingType == '1-5'
        .rating-options
            for option in [1, 2, 3, 4, 5]
                label.rating-option
                    input.response-value(type='radio' name=id value=option)
                    span= option
    else
        .rating-options
            for option in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
                label.rating-option
                    input.response-value(type='radio' name=id value=option)
                    span= option
        .rating-labels.row
            .col-md-6.left
                small.text-muted Not likely
            .col-md-6.right.text-right
                small.text-muted Very likely

