.radio(class=(showLanguageDropdown ? '' : 'active'))
    label
        input(type="radio" name="toggleLanguages" value="all" checked=(!showLanguageDropdown) disabled=isPublished)
        span
        | Any language
.radio(class=(showLanguageDropdown ? 'active' : ''))
    label
        input(type="radio" name="toggleLanguages" value="select" checked=showLanguageDropdown disabled=isPublished)
        span
        | Only the languages I choose

    div.content
        .form-group
            select.form-control(id="select-to" placeholder="Search for a language" multiple="multiple" disabled=isPublished)
