if loading
    .spinner
else
    unless hasContent
        .animated.fadeIn
            if isFiltered
                h4 No content matches these filters.
                p
                    a(data-action="clearFilterState") Clear filters
                    | &nbsp;to see more content or&nbsp;
                    a(data-action="createContentWithDomain") create something new
                    | .
