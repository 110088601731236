.bigbody
.registration-container
    .registration-header
        h1 Create amazing user experiences.
        h2 You're 30 seconds away from your first Appcues creation.

    style.
        #hs-beacon {
            display: none!important;
        }

    .auth-page.container.row-flex.flex-wrap.reg-panel
        .registration-body-content
            if hasError
                .validation-error
                    .alert.alert-warning
                        if error === 'ACCOUNT_EXISTS'
                            |  Looks like you already have an account. #[a(href='/login?email=#{email}') Log in now].
                        else if error === 'NO_FREEMAIL'
                            |  Please provide your work email. We promise not to share it and will only use it for your Appcues account.
                        else
                            strong Whoops!
                            |  There was an error creating your account. Check that everything looks good and try again.
                            br
                            |   If this persists, we might be having a problem. Check our #[a(href='http://status.appcues.com/', target='_blank') status page] for more information.

            .panel.panel-default.full-screen-panel
                .panel-body.register-page
                    if showRedirectBackdrop
                        .redirect-backdrop
                        .redirect
                            h2 Thanks! You'll be redirected in a moment

                    form.auth-form(role='form',autocomplete='on')
                        if !isGoogleProvider
                            .form-group.work-email-group
                                label(for='email') Work email

                                .single-group
                                    input.form-control(name='email', id='email', type='email', placeholder='name@company.com', autofocus, value=email, autocomplete='email')

                                    if !expandForm
                                        button.btn.btn-primary(class='email-next', type='button') Next

                        if expandForm || isGoogleProvider
                            if !isGoogleProvider
                                div(class="row#{ showName ? '' : ' hidden' }")
                                    .col-md-6
                                        .form-group
                                            label(for='firstname') First
                                            input.form-control(name='firstname', id='firstname', type='text', placeholder='Taylor', autocomplete='given-name', value=firstname)
                                    .col-md-6
                                        .form-group
                                            label(for='lastname') Last
                                            input.form-control(name='lastname', id='lastname', type='text', placeholder='Hendrinks', autocomplete='family-name', value=lastname)

                            div(class="form-group request-demo hidden")
                                input.form-control(name='request_demo', id='request_demo', type='checkbox', checked=false )
                                label(for='request_demo') Would you like help getting started with Appcues? We'll get in touch with you.

                            if !isGoogleProvider
                                .form-group
                                    label(for='password') Password
                                    input.form-control(name='password', type='password', id='password', placeholder='Minimum of 6 characters')

                            button.btn.btn-block.btn-primary(type='submit') Sign up

                if !isGoogleProvider
                    .divider.text-center
                        | OR

                    a.well.well-sm.text-center.google-auth
                        .g-logo
                            img(src='/images/google-g-logo.png')
                        span Sign up with Google for Work

                hr
                .form-login-container
                    span Already using Appcues?&nbsp;
                    a(href='/login') Log in here →

            p.text-center.signup-terms By signing up for Appcues, you agree to our
                br
                a(href='https://www.appcues.com/terms/', target='_blank') Terms of Service
                | &nbsp;and&nbsp;
                a(href='https://www.appcues.com/privacy', target='_blank') Privacy Policy
                | .
