mixin integration(data)
    .integration(
        data-id=data.integrationId,
        data-provider=data.id,
        class={
            enabled: data.isEnabled,
            pending: data.status == 'pending',
            configuring: data.settings !== undefined && data.status == 'configuring' })

        .text-center.integration-logo
            img(src=data.imageUrl, title=data.name, width='100%')

        if data.isEnabled && data.status != 'pending'
            p.text-success.text-center: strong
                i.fa.fa-check
                | &nbsp;#{data.name} integration active

        .integration-action
            if data.status == 'pending'
                .btn.btn-block.btn-success.active
                    i.fa.fa-spinner.fa-pulse
                    | &nbsp;Setting up #{data.name}
            else if data.isEnabled
                a.btn.btn-block.btn-danger.remove-integration Deactivate
            else if data.settings && data.status == 'configuring'
                form.integration-form(action='#{integrationUrl}/auth/#{data.id}')
                    for field in data.settings
                        .form-group
                            label= field.label
                            input.form-control(name=field.name, type=field.type, value=data[field.name], placeholder=field.placeholder, required)
                    button.btn.btn-block.btn-success(type='submit') Finish activation
                a.cancel-configuration Cancel
            else if !data.placeholder
                a.btn.btn-block.btn-success.activate-integration Activate #{data.name}
