.modal-dialog
    .modal-content
        .modal-header
            a.close(data-dismiss="modal") &times;
            h4.modal-title Create a new segment

        .modal-body
            p.text-center Save these property filters as a segment that can be reused for targeting other content.

            hr

            form.form-horizontal
                .form-group
                    label.control-label.col-sm-2(for='name') Name
                    .col-sm-10
                        input.form-control(name='name' placeholder='e.g. "Trial users" or "Beta customers"')

                .form-group
                    label.control-label.col-sm-2 Filters
                    .col-sm-10
                        .form-control-static
                            for options, name in attrs.properties
                                div
                                    strong= propertyDisplay(name)
                                    | &nbsp;#{operatorDisplay(name, options)}&nbsp;
                                    strong= valueDisplay(name, options)

        .modal-footer
            a.btn.btn-default(data-dismiss="modal") Cancel
            a.btn.btn-primary(data-action="save") Save

