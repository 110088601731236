.channel-settings.appcues-style
    label.checkbox.pull-right
        input(type='checkbox' name='facebookEnabled' checked=facebookEnabled)
        span Enabled
    h3 Facebook
    textarea(name='facebookContent')= facebookContent

hr

.channel-settings.appcues-style
    label.checkbox.pull-right
        input(type='checkbox' name='twitterEnabled' checked=twitterEnabled)
        span Enabled
    h3 Twitter
    textarea(name='twitterContent')= twitterContent

.controls.clearfix
    a.appcues-style.btn.btn-link.btn-link-danger.btn-sm(data-action='delete') Delete
    a.appcues-style.btn.btn-primary.btn-sm.pull-right(data-action='saveAndClose') Save
    a.appcues-style.btn.btn-default.btn-sm.pull-right(data-action='close') Cancel

