.hover-controls
    if componentType && componentType != 'default' && componentType != 'pacman'
        a.hint--top(data-action='edit' title='Edit')
            i.fa.fa-fw.fa-pencil
            | &nbsp;Edit
    a.hint--top(data-action='delete' title='Remove')
        i.fa.fa-fw.fa-trash
        | &nbsp;Remove
    a.hint--top(title='Reorder').component-drag-handle
        i.fa.fa-fw.fa-arrows-v
        | &nbsp;Reorder

if content
    != content
else
    .empty-component.text-center.appcues-style
        if componentType === 'editor'
            i.fa.fa-lg.fa-paragraph
            span  Click to add your text
        else if componentType === 'image'
            i.fa.fa-lg.fa-image
            span  Click to add your image
        else if componentType === 'video'
            i.fa.fa-lg.fa-film
            span  Click to add your video
        else if componentType === 'form'
            i.fa.fa-lg.fa-check-square-o
            span  Click to add your form
        else if componentType === 'hero'
            i.fa.fa-lg.fa-image
            span  Click to add your hero
