.checkbox(class=(showRedirectUrl ? 'active' : ''))
    label
        input(type="checkbox" name="pageRedirect" checked=(showRedirectUrl ? true : false) disabled=isPublished)
        span
        | Redirect to a different page
    .content
        span.subtext
            | If you have personalization tokens, you can include them in double curly braces (e.g. /dashboard?email={{email}}).
        input.form-control(name='redirectUrl' value=rule.redirectUrl placeholder="e.g. /dashboard or http://appcues.com/" disabled=isPublished)
        div.radio
            label
                input(type="checkbox" name="redirectNewTab" value="_blank" checked=(rule.redirectNewTab) disabled=isPublished)
                span
                | Open page in a new tab

.checkbox(class=(showNextContent ? 'active' : ''))
    label
        input(type="checkbox" name="contentTrigger" checked=(showNextContent ? true : false) disabled=isPublished)
        span
        | Trigger Appcues content
    .content
        if flows.length || hotspotGroups.length || coachmarkGroups.length
            select.form-control(name='nextContent' disabled=isPublished)
                option(value="" selected=(!rule.nextContentId ? 'selected' : false))
                if flows.length
                    optgroup(label="Modals")
                        each flow in flows
                            - selected = (rule.nextContentId && (rule.nextContentId === flow.id))
                            option(value=flow.id data-data=_.pick(flow, ['name', 'published', 'id']) selected=(selected ? 'selected' : false))= flow.name
                if hotspotGroups.length
                    optgroup(label="Hotspots")
                        each hotspotGroup in hotspotGroups
                            - selected = (rule.nextContentId && (rule.nextContentId === hotspotGroup.id))
                            option(value=hotspotGroup.id data-data=_.pick(hotspotGroup, ['name', 'published', 'id']) selected=(selected ? 'selected' : false))= hotspotGroup.name
                if coachmarkGroups.length
                    optgroup(label="Coach Marks")
                        each coachmark in coachmarkGroups
                            - selected = (rule.nextContentId && (rule.nextContentId === coachmark.id))
                            option(value=coachmark.id data-data=_.pick(coachmark, ['name', 'published', 'id']) selected=(selected ? 'selected' : false))= coachmark.name
            if nextContentDraftWarning
                .small.help-block Note: The selected content is not currently published. It must be published to be triggered automatically.
        else
            .help-block Whoops! Looks like you don't have any content created yet. #[a(href='/create' target='_blank') Go create something!]

