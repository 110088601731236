extends one-column

block title
    .page-heading
        .page-heading-title
             h2 Account
        if isIFramed
            .page-heading-actions
                a.btn.btn-primary(href="/logout") Log Out

block content
    if auth.spoofing && isIFramed
        p Currently acting as account ##{accountId}

    if !isIFramed && user.isAdmin
        .panel.panel-default
            .panel-body.text-center
                form.su
                    if auth.spoofing
                        p Currently acting as account ##{accountId}
                        input(type='hidden', name='accountId', value='')
                        button.btn.btn-danger.btn-block.btn-sm End Session
                    else
                        p Log in as another account
                        .input-group
                            input.input-sm.form-control(type='text', placeholder='23', name='accountId')
                            span.input-group-btn: button.btn.btn-danger.btn-sm Switch

    if currentMember
        .panel.panel-default
            .panel-heading
                h3.panel-title Profile

            .panel-body.account-users-list
                .media
                    .media-left
                        img.media-object.img-rounded(src=currentMember.avatarUrl, width="128")
                    .media-body
                        p
                            - var name = currentMember.fullname || 'Unknown name'
                            - var role = currentMember.role || 'Unknown role'
                            | #[strong= name], #{role}
                        p= currentMember.email
                        p Member since #{moment(currentMember.createdAt).format('l')}

                        p: a.btn-link(data-action='editProfile') Change your profile or password

    .panel.panel-default
        .panel-heading
            h3.panel-title Account Settings
        .panel-body
            if savedAccount
                .alert.alert-success(role="alert")
                    strong
                        i.fa.fa-check-circle
                        | &nbsp;Saved
            else if isSavingAccount
                .alert.alert-info(role="alert")
                    strong
                        i.fa.fa-circle-o-notch.fa-spin
                        | &nbsp;&nbsp;Saving&hellip;
            form.account-form.saveable-form
                .form-group
                    label(for='company') Company name
                    input#company.form-control(type='text', name='company', value=account.company, placeholder='New England Patriots')

                - var siteUrlErrorMsg = accountValidationError.siteUrl
                - var siteUrlClass = siteUrlErrorMsg ? 'has-error' : ''
                .form-group(class=siteUrlClass)
                    label(for='siteUrl') Web application domain
                    input#siteUrl.form-control(type='text', name='siteUrl', value=account.siteUrl, placeholder='https://app.patriots.com')

                    if siteUrlErrorMsg
                        p.help-block= siteUrlErrorMsg

                .form-group
                    label(for='domains') Available domains
                    input#domains.form-control(type='text', name='domains', value=account.domains, placeholder='patriots.com,nfl.com,espn.go.com')
                    p.help-block A comma-separated list of domains that you can use in #[a(href='http://docs.appcues.com/article/15-target-domain', target='_blank') domain targeting].

                if isBeta || isBufferEnabled
                    .form-group
                        label Frequency capping
                        p.help-block Limit how often customers can see a message from Appcues.

                        .checkbox
                            label
                                input.toggle-buffer(type='checkbox', checked=isBufferEnabled)
                                | Enable frequency cap

                        if isBufferEnabled
                            .input-group
                                input.form-control(name='buffer', value=buffer, type='text', placeholder='6', autofocus)
                                span.input-group-addon hours

                button.btn.disabled.pull-right(type='submit') Save

    .panel.panel-default
        .panel-heading
            h3.panel-title Account API Keys
        .panel-body
            .form-group
                label Account ID
                input.form-control(type='text', readonly, value=accountId)
            .form-group
                label API Key
                if hideApiKey
                    input.form-control(type='text', readonly, value='Contact us')
                else
                    input.form-control(type='text', readonly, value=account.uuid)

    .panel.panel-default
        .panel-heading
            h3.panel-title Customer Support
        .panel-body
            if savedSupport
                .alert.alert-success(role="alert")
                    strong
                        i.fa.fa-check-circle
                        | &nbsp;Saved
            else if isSavingSupport
                .alert.alert-info(role="alert")
                    strong
                        i.fa.fa-circle-o-notch.fa-spin
                        | &nbsp;&nbsp;Saving&hellip;

            p To improve your support experience, you can provide our team with a demo login for your application. We use that info to troubleshoot issues in your test environment and verify any flows you've created look extra beautiful.

            p #[strong Security note:] please do not provide us with login credentials that could jeopardize the security of your organization. Appcues claims no responsibility over the security of your product or company, so we ask that you adhere to the security guidelines of your organization before providing this information.

            p If you are unsure, run this by one of the techie folks on your team.

            br

            form.support-form.saveable-form
                .form-group
                    label Test environment URL
                    input.form-control(type='text', name='testUrl', value=account.testUrl, placeholder='https://staging.example.com')

                .form-group
                    label Test username
                    input.form-control(type='text', name='testUsername', value=account.testUsername, placeholder='test@example.com')

                .form-group
                    label Test password
                    input.form-control(type='password', name='testPassword', value=account.testPassword, placeholder='test')

                button.btn.disabled.pull-right(type='submit') Save
