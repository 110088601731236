.radio(class=(pageOption === 'any') ? 'active' : '')
    label
        input(type="radio" name="pageOption" value="any" checked=(pageOption === 'any') disabled=isPublished)
        span
        | Any page

if bookmarks.length > 0
    .radio(class=(pageOption === 'bookmark') ? 'active' : '')
        label
            input(type="radio" name="pageOption" value="bookmark" checked=(pageOption === 'bookmark') disabled=isPublished)
            span
            | On a saved bookmark

        div.content
            select.form-control(name='bookmarkId' disabled=isPublished)
                option
                for bookmark in bookmarks
                    option(value=bookmark.id selected=(bookmarkId === bookmark.id))= bookmark.name

            if selectedBookmark
                .text-muted.small
                    if selectedBookmark.isRegex
                        | Pages that match #[strong= selectedBookmark.where] as a regular expression
                    else
                        | Pages that match #[strong= selectedBookmark.where] exactly


.radio(class=(pageOption === 'exact') ? 'active' : '')
    label
        input(type="radio" name="pageOption" value="exact" checked=(pageOption === 'exact') disabled=isPublished)
        span
        | At a specific URL

    div.content
        .form-group(class=(validationError ? ' has-error ' : '') + (validationWarning ? ' has-warning ' : ''))
            input.form-control(name='where' value=where disabled=isPublished)
            if validationError
                small.text-danger= validationError.where
            if validationWarning
                small.text-warning= validationWarning.where

        a.btn.btn-outline.btn-sm(data-action="saveBookmark") Save as a bookmark

.radio(class=(pageOption === 'simpleRegex') ? 'active' : '')
    label
        input(type="radio" name="pageOption" value="simpleRegex" checked=(pageOption === 'simpleRegex') disabled=isPublished)
        span
        | Match URL

    div.content
        .form-group.row(class=(validationError ? ' has-error ' : '') + (validationWarning ? ' has-warning ' : ''))
            .col-sm-6
                select.where-operator.form-control(name="whereOperator" disabled=isPublished)
                    if whereOperator
                        option(value=whereOperator, selected)

            .col-sm-6
                input.form-control(name='whereString' value=whereString disabled=isPublished placeholder="/accounts")
                if validationError && validationError.simpleRegex
                    small.text-danger= validationError.simpleRegex
                if validationWarning && validationWarning.simpleRegex
                    small.text-warning= validationWarning.simpleRegex

.radio(class=(pageOption === 'regex') ? 'active' : '')
    label
        input(type="radio" name="pageOption" value="regex" checked=(pageOption === 'regex') disabled=isPublished)
        span
        | Regex Match URL

    div.content
        .form-group(class=(validationError ? ' has-error ' : '') + (validationWarning ? ' has-warning ' : ''))
            input.form-control(name='where' value=where disabled=isPublished)
            if validationError && validationError.regex
                small.text-danger= validationError.regex
            if validationWarning && validationWarning.regex
                small.text-warning= validationWarning.regex

        a.btn.btn-outline.btn-sm(data-action="saveBookmark") Save as a bookmark
        a.btn.btn-link.btn-sm(data-action="toggleRegexHelp") Need help with URL patterns?

        div.regexHelp(style="display: none")
            p
            p
                | Common patterns:&nbsp;
                a(data-pattern='/.*') any page
                | ,&nbsp;
                a(data-pattern='/[0-9]+') numbers
                | ,&nbsp;
                a(data-pattern='^/abc') starts with "abc"
                | . Want even more? Learn&nbsp;
                a(href='http://www.cheatography.com/davechild/cheat-sheets/regular-expressions/', target='_blank') about regular expressions


//- Taco options are now in beta and only available for modals.
if isBeta && contentType === 'flow'
    hr

    p If you've installed #[a(href='http://docs.appcues.com/article/17-in-app-widget', target='_blank') our in-app widget] on your site, choose how this flow appears there.

    .radio
        label
            input(type="radio", name="widgetFilter", value='page', checked=(widgetFilter === 'page' || !widgetFilter), disabled=isPublished)
            span
            | Include only when the URL matches
    .radio
        label
            input(type="radio", name="widgetFilter", value='include', checked=(widgetFilter === 'include'), disabled=isPublished)
            span
            | Include in all widgets
    .radio
        label
            input(type="radio", name="widgetFilter", value='exclude', checked=(widgetFilter === 'exclude'), disabled=isPublished)
            span
            | Exclude from all widgets
