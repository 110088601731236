p Adding a conversion goal will let you track how many users make it to a certain URL after completing this flow.

if hasGoal
    .display-table
        .table-cell(style="width: 170px")
            select.form-control(name='isRegex' disabled=rule.published)
                option(value='false' selected=!goal.isRegex) Match exactly
                option(value='true' selected=goal.isRegex) Regular expression
        .table-cell.cell-space
            input.form-control(name='where' value=goal.where disabled=rule.published placeholder="e.g. /checkout/complete/")
        .table-cell.text-center(style="width: 16px")
            a.btn.btn-link.hint--left(data-action='clearGoal' data-hint='Remove conversion goal')
                i.fa.fa-trash
else
    if rule.published
        a(data-action='unpublishContent') Unpublish
        | &nbsp;to add a conversion goal.
    else
        a(data-action='addGoal') Add a conversion goal

