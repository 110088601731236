.field-label
    if isEditable
        input.label-value(placeholder="Add label..." value=label)
        .field-actions
            a.field-action.field-drag-handle.hint--left(data-hint="Drag and drop to reorder fields")
                i.fa.fa-sort
            .btn-group
                a.field-action.field-options.hint--left.control-link(data-hint="Field options" role="button" tabindex="-1")
                    i.fa.fa-cog
            a.field-action.hint--left.control-link(data-action="removeField" data-hint="Delete this field")
                i.fa.fa-trash-o

    else
        label.label-display(for=id) #{label}

.field-controls
    if isEditable
        .field-options
            if fieldOptions && fieldOptions.length > 0
                for option in fieldOptions
                    .field-option-wrapper
                        label.field-option
                            i.fa.fa-sort.option-drag-handle
                            input.option-value(placeholder="Enter an option", value=option)
                            a.remove-field-option.text-muted.pull-right
                                | &times;
            else
                .field-option-wrapper
                    label.field-option
                        i.fa.fa-sort.option-drag-handle
                        input.option-value(placeholder="Enter an option")
                        a.remove-field-option.text-muted.pull-right
                            | &times;

        a.add-field-option.control-link
            i.fa.fa-plus
            | &nbsp;Add an option

        .field-option-wrapper.hidden.field-option-template
            label.field-option
                i.fa.fa-sort.option-drag-handle
                input.option-value(placeholder="Enter an option")
                a.remove-field-option.text-muted.pull-right
                    | &times;
    else
        .field-options
            if fieldOptions
                for option in fieldOptions
                    if option
                        label.field-option
                            input.response-value(type='radio' name=id value=option)
                            span= option

