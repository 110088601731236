.container
    .row
        .col-md-12
            h2
                if account.company
                    =account.company
                else
                    em No company name set
                | &nbsp;
                small.text-muted ##{accountId}

            div.checklist.panel.panel-default
                .panel-heading Checklist
                table.table.table-striped.table-bordered
                    tr.show-property-keys
                        td.icon
                            if _.isEmpty(properties)
                                i.fa.fa-times-circle.text-danger.fa-lg
                            else
                                i.fa.fa-check-circle.text-success.fa-lg
                        td.description
                            if properties && properties._updatedAt
                                small.text-muted.pull-right.hint--left(data-hint=(moment(properties._updatedAt).toISOString()))
                                    | Last identified&nbsp;
                                    = moment(properties._updatedAt).fromNow()
                            if _.isEmpty(properties)
                                span Embed script not installed
                            else
                                span Embed script installed
                                .property-keys.clearfix
                                    ul.list-unstyled
                                        for prop in properties
                                            li
                                                span.hint--top(data-hint="e.g. #{prop.value}")= prop.key

                    tr
                        td.icon
                            if account.isTrial && account.isTrialExpired
                                i.fa.fa-times-circle.text-danger.fa-lg
                            else if account.isTrial
                                i.fa.fa-ellipsis-h.text-info.fa-lg
                            else
                                i.fa.fa-check-circle.text-success.fa-lg
                        td.description
                            if account.isTrial
                                if account.isTrialExpired
                                    small.text-muted.pull-right Expired #{moment(account.createdAt).add(account.trialLengthDays, 'days').fromNow()}
                                    span Trial expired
                                else
                                    small.text-muted.hint--left.pull-right(data-hint="#{account.trialLengthDays}-day trial") Expires #{moment(account.createdAt).add(account.trialLengthDays, 'days').fromNow()}
                                    span Free trial
                            else
                                span.text-muted.pull-right: i.fa.fa-money.fa-lg
                                span Paid

                    tr
                        td.icon
                            if flows.length > 0
                                strong
                                    i.fa.fa-lg.text-success= flows.length
                            else
                                strong
                                    i.fa.fa-lg.text-danger= flows.length
                        td.description
                            span
                                if flows.length == 1
                                    | Flow
                                else
                                    | Flows
                                | &nbsp;created

                    tr
                        td.icon
                            if publishedFlows.length > 0
                                i.fa.fa-lg.text-success= publishedFlows.length
                            else
                                i.fa.fa-lg.text-danger= publishedFlows.length
                        td.description
                            span
                                if publishedFlows.length == 1
                                    | Flow
                                else
                                    | Flows
                                | &nbsp;currently published

                    tr
                        td.icon
                            if hasHistory
                                i.fa.fa-check-circle.fa-lg.text-success
                            else
                                i.fa.fa-times-circle.fa-lg.text-danger
                        td.description
                            if hasHistory
                                span.hint--bottom(data-hint="We have tracked flow impressions from staging/production") Flows shown
                            else
                                span.hint--bottom(data-hint="No flow history recorded from staging/production") No flows seen by visitors

            if users
                .user.panel.panel-default
                    .panel-heading Users
                    table.table
                        for user in users
                            tr
                                td.image(style="background-image: url(#{user.avatarUrl})")
                                td.user-details
                                    if user.fullname
                                        strong= user.fullname
                                    else
                                        em No full name set
                                    if user.id == currentUserId
                                        | &nbsp;&nbsp;
                                        small.text-muted.hint--right(data-hint="That's you")
                                            i.fa.fa-star
                                    div: small= user.email
                                td.text-right
                                    p
                                        small.hint--left(data-hint="#{moment(user.lastSeenTimestamp).toISOString()}").text-muted Last seen #{moment(user.lastSeenTimestamp).fromNow()}
                                    if user.isAdmin
                                        div.text-muted.hint--left(data-hint="Admin")
                                            i.fa.fa-lg.fa-user-secret


            if flows
                .flows.panel.panel-default
                    .panel-heading Flows
                    table.table.table-striped
                        for flow in flows
                            tr
                                td.image(style="background-image: url(#{flow.previewUrl || '/images/integrations-bg.png'})")
                                td.main
                                    h5
                                        a(href="/flows/#{flow.id}/edit#{viewAs}" target="_blank")= flow.name
                                    small.text-muted Updated #{moment(flow.updatedAt).fromNow()}
                                td.stats
                                    if flow.totalUniqueViews
                                        a.text-muted.hint--left(href="/flows/#{flow.id}/stats#{viewAs}" target="_blank" data-hint="View Analytics")
                                            i.fa.fa-bar-chart-o
                                td.indicators
                                    for step in flow.steps
                                        if step.stepType && step.content
                                            small.text-muted
                                                i.fa.fa-square &nbsp;
                                        else if !step.stepType
                                            small.text-danger.hint--left(data-hint="No step type selected")
                                                i.fa.fa-square &nbsp;
                                        else
                                            small.text-danger.hint--left(data-hint="No content set")
                                                i.fa.fa-square &nbsp;
                                td.published-flag
                                    if flow.published
                                        span.label.label-success LIVE
                                    else
                                        span.label.label-default DRAFT

            if hotspotGroups
                .hotspots.panel.panel-default
                    .panel-heading Hotspots
                    table.table.table-striped
                        for hotspotGroup in hotspotGroups
                            tr
                                td.image(style="background-image: url(#{hotspotGroup.previewUrl})")
                                td.main
                                    h5
                                        a(href="/hotspots/#{hotspotGroup.id}/edit#{viewAs}" target="_blank")= hotspotGroup.name
                                    small.text-muted Updated #{moment(hotspotGroup.updatedAt).fromNow()}
                                td.indicators
                                    for hotspot in hotspotGroup.hotspots
                                        if hotspot.nodeMissing
                                            small.text-danger.hint--left(data-hint="Unable to find element")
                                                i.fa.fa-circle &nbsp;
                                        else
                                            small.text-muted
                                                i.fa.fa-circle &nbsp;
                                td.published-flag
                                    if hotspotGroup.published
                                        span.label.label-success LIVE
                                    else
                                        span.label.label-default DRAFT

