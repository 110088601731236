.extend-trial-container
    .extend-trial-message
        br
        br
        if hasStripeAccount && !isTrialExpired
            p Looks like you've registered a credit card with us! High five!
            p
                img(src="/images/happy-werewolf.gif" width="400px")
            p
                strong
                    a(href="/") Now go play with Appcues!
            if isTrial
                p Your trial is set to expire on #{trialExpiryReadable}.
        else
            if isTrialExpired
                if trialExtended
                    p It looks like you've already extended your trial once. As much as we'd like to, we just can't let you do it again. Please contact us to get your account status straigtened out.
                    p We're always here to help.
                    p
                        a(href="mailto:support@appcues.com") support@appcues.com
                else
                    p Alright! #{trialExtensionDays} more days are being added onto your Appcues trial. Just add your card info below and your account will open.*
                    br
                    button.btn.btn-primary.add-card Add card
                    br
                    br
                    p You can only do this one time. If you want some help making the most of it, just email us and tell us how we can help.
                    p
                        a(href="mailto:support@appcues.com") support@appcues.com
                    br
                    p.small-text *At the end of the #{trialExtensionDays} days, if you don't cancel your account, your card will be charged $99 monthly (our smallest plan). We'll be sure to remind you 1 day before the charge.
            else
                p Looks like you don't need to extend your trial yet!
                p
                    strong
                        a(href="/") Go play with Appcues!
