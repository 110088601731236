.list-info.row-flex.row-flex-justify.row-flex-centered
    .list-filters
        ul.nav.filters-nav
            li.filter-by Filter by:
            li.dropdown
                a.dropdown-toggle(data-toggle='dropdown')
                    span(class=(filterState.type == null ? '' : 'filtered')) #{typeDisplay}&nbsp;
                    i.fa.fa-angle-down
                ul.dropdown-menu
                    li.dropdown-header Filter by pattern
                    li(class=(filterState.type == null ? 'active' : ''))
                        a(data-property='type' data-value=null) All Patterns
                    li(class=(filterState.type == 'flows' ? 'active' : ''))
                        a(data-property='type' data-value='flows') Modals
                    li(class=(filterState.type == 'slideouts' ? 'active' : ''))
                        a(data-property='type' data-value='slideouts') Slideouts
                    li(class=(filterState.type == 'hotspotGroups' ? 'active' : ''))
                        a(data-property='type' data-value='hotspotGroups') Hotspots
                    li(class=(filterState.type == 'tooltipGroups' ? 'active' : ''))
                        a(data-property='type' data-value='tooltipGroups') Tooltips
                    li(class=(filterState.type == 'coachmarkGroups' ? 'active' : ''))
                        a(data-property='type' data-value='coachmarkGroups') Coach Marks
                    li(class=(filterState.type == 'journeys' ? 'active' : ''))
                        a(data-property='type' data-value='journeys') Multiple

            li.dropdown
                a.dropdown-toggle(data-toggle='dropdown')
                    span(class=(filterState.state === null ? '' : 'filtered')) #{stateDisplay}&nbsp;
                    i.fa.fa-angle-down
                ul.dropdown-menu
                    li.dropdown-header Filter by status
                    li(class=(filterState.state == null ? 'active' : ''))
                        a(data-property='state' data-value=null) Any status
                    li(class=(filterState.state == 'published' ? 'active' : ''))
                        a(data-property='state' data-value='published') Live
                    li(class=(filterState.state == 'draft' ? 'active' : ''))
                        a(data-property='state' data-value='draft') Draft
                    li(class=(filterState.state == 'archived' ? 'active' : ''))
                        a(data-property='state' data-value='archived') Archived


            li.dropdown
                a.dropdown-toggle(data-toggle='dropdown')
                    span #{sortDisplay}&nbsp;
                    i.fa.fa-angle-down
                ul.dropdown-menu
                    li.dropdown-header Sort by
                    li(class=(filterState.sortBy == 'default' ? 'active' : ''))
                        a(data-property='sortBy' data-value='default') Relevance
                    li(class=(filterState.sortBy == 'updatedAt' ? 'active' : ''))
                        a(data-property='sortBy' data-value='updatedAt') Recently updated
                    li(class=(filterState.sortBy == 'createdAt' ? 'active' : ''))
                        a(data-property='sortBy' data-value='createdAt') Recently created
                    li(class=(filterState.sortBy == 'name' ? 'active' : ''))
                        a(data-property='sortBy' data-value='name') Name (A-Z)

            li
                span.search
                    label.hint--left(for='nameFilter' data-hint="Filter by name")
                        i.fa.fa-search
                    input(name='nameFilter' id='nameFilter' value=filterState.nameFilter
                          class=(filterState.nameFilter ? 'name-filter-active' : ''))

    a.btn.btn-link.no-padding(data-action="manageCategories")
        i.fa.fa-cog
        span.hidden-sm.hidden-xs Manage Categories
