#build-location-bar
    .container
        h5
            | Enter a URL or navigate to a page for your&nbsp;
            if contentType === 'hotspots'
                | hotspots
            else if contentType === 'coachmarks'
                | coachmarks
            else
                | flow
            | &nbsp;below. Advanced targeting will be done later.
            small.pull-right
                if useEager
                    a(data-action="switchPreviewMethod") Is the preview not working with your site? Try this method instead!
                else
                    a(data-action="switchPreviewMethod") Switch back to the standard preview method.
        .input-wrapper
            input(name="previewUrl" placeholder="Choose a URL to preview your content", value=previewUrl)
            span.indicator
                a.loading
                    i.fa.fa-lg.fa-circle-o-notch.fa-spin.text-muted
                a.navigate(data-action="navigateFrame")
                    i.fa.fa-lg.fa-arrow-right

#build-preview-frame
