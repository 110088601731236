.col-sm-4
    select.rule-property.rule-property-name(placeholder='Select a user property' disabled=!isEditable)
        if filter.name
            option(value=filter.name, selected) #{getPropertyDisplayName(filter.name)}

.col-sm-3(style='padding: 0')
    select.rule-property.rule-property-operator(disabled=!isEditable)
        if filter.operator
            option(value=filter.operator, selected)
.col-sm-5
    .input-group.buttonless
        - var filterType = filter.type || ''
        - var filterValue = filter.value != null ? filter.value : ''
        - var placeholder = (filter.placeholder != null && !isDateFilter) ? filter.placeholder : 'Set a value'
        - var hideClass = (filter.operator == '?' || filter.operator == '!?' || filter.operator == 'in' || filter.operator == 'not in') ? 'hidden' : ''
        if isFiniteProp
            select.rule-property.rule-property-value(placeholder=placeholder disabled=!isEditable)
                if filterValue
                    option(value=filterValue, selected)
        else if filter.operator === '?' || filter.operator === '!?'
            input.rule-property.rule-property-value.form-control(type='hidden', data-type=filterType, value="#{filterValue}" disabled=!isEditable)
        else if filter.operator === 'in' || filter.operator === 'not in'
            a.form-control.show-modal(data-filter-id=filter.id style="cursor: pointer; text-decoration: none;") #{filter.valueCount} #{filter.valueCount == 1 ? 'value' : 'values'}
            input.rule-property.rule-property-values-list.form-control(type='hidden' data-type=filterType data-filter-id=filter.id value="#{filter.valuesList}" disabled=!isEditable)
            input.rule-property.rule-property-is-sensitive.form-control(type='hidden' data-type=filterType data-filter-id=filter.id value="#{filter.isSensitive}" disabled=!isEditable)
        else
            input.rule-property.rule-property-value.form-control(class=hideClass, type='text', placeholder=placeholder, data-type=filterType data-filter-id=filter.id value="#{filterValue}" disabled=!isEditable)

        if filter.operator === '> ago' || filter.operator === '< ago'
            .rule-property-value-label.input-group-addon days ago
        else if filter.operator === 'within'
            .rule-property-value-label.input-group-addon days

        span.input-group-btn
            if isEditable
                a.btn.btn-link.remove-rule-property.hint--left(data-hint='Remove this filter')
                    i.fa.fa-fw.fa-lg.fa-times
