extends auth-form

block content
    if isNewUser
        p
            | You're one step away. Just
            strong  choose your password
            |  to keep your account.
    else
        p Please choose a new password.

    form.reset-form(role='form')
        .form-group
            if email
                input.form-control(type='email', value=email, disabled)
                input(name='email', type='hidden', value=email)
            else
                input.form-control(name='email', type='email', placeholder='Email')

        .form-group
            input.form-control(name='password', type='password', placeholder='Password')

        input(name='oldPassword', type='hidden', value=password)
        input.hidden(name='rememberMe', type='checkbox', checked='true')

        button.btn.btn-block.btn-primary(type='submit')
            if isNewUser
                | Start onboarding customers
            else
                | Reset my password
