.modal-dialog.modal-center
    .modal-content
        .modal-body.text-center
            block body
                h4= title
                p= text

        unless hideFooter
            .modal-footer
                block footer
                    - var cancelText = cancelButtonText || "Cancel"
                    - var confirmText = confirmButtonText || "Continue"
                    a.btn.btn-link(data-event='cancel', data-dismiss='modal') #{cancelText}
                    button.btn.btn-success.pull-right(data-event='confirm', type='button') #{confirmText}
