mixin stepButtons(stepButtons, defaultText, step, isEditable, showControls, buttonClasses, alignment, type, visible)
    if isEditable && showControls && (!stepButtons || !stepButtons.length)
        div.add-icon.hint--right(data-action='addButton', data-hint="Add button", data-alignment=alignment, data-type=type, data-default-text=defaultText)
            i.fa.fa-plus
    else
        for stepButton, i in stepButtons
            -var stepButtonStyle = stepButton.get('style') ? JSON.parse(stepButton.get('style')) : ""
            -var backgroundColor = stepButtonStyle && stepButtonStyle['background-color'] ? stepButtonStyle['background-color'] : "#FFFFFF"
            -var textColor = stepButtonStyle && stepButtonStyle['color'] ? stepButtonStyle['color'] : "#000000"
            -if(!visible){ stepButtonStyle += "display:none;"; }

            a(class='appcues-button step-button #{buttonClass}', data-step='#{step}', data-button-cid=stepButton.cid, style=stepButtonStyle)
                if isEditable
                    div(data-button-cid=stepButton.cid, contenteditable='true', data-default-text='#{defaultText}')
                        if stepButton.get('text')
                            = stepButton.get('text')
                        else
                            | #{defaultText}
                else
                    if stepButton.get('text')
                        = stepButton.get('text')
                    else
                        | #{defaultText}


            if isEditable && showControls
                .hover-controls
                    span.color-picker-button-container(data-button-cid=stepButton.cid, data-color-attribute='background-color')
                        a.hint--top.color-picker-button(title='Edit', data-color=backgroundColor, contenteditable=false)
                            i.fa.fa-fw.fa-square
                            | &nbsp;Button Color
                        span.editableValue(contenteditable=true)
                            = backgroundColor
                    span.color-picker-button-container(data-button-cid=stepButton.cid, data-color-attribute='color')
                        a.hint--top.color-picker-button(title='Edit', data-color=textColor)
                            i.fa.fa-fw.fa-font
                            | &nbsp;Text Color
                        span.editableValue(contenteditable=true)
                            = textColor
                    span(data-button-cid=stepButton.cid)
                        a.hint--top(data-action='removeButton', title='Remove')
                            i.fa.fa-fw.fa-trash
                            | &nbsp;Remove

section.step-action-content
    block content
        header.text-center
            h2 Whoopsie!
            p We can't find that kind of step. You might have better luck with a different type.

block buttons
    -var editableClass = isEditable ? 'editable-buttons' : ''
    .appcues-actions.step-action-buttons(class=editableClass, data-step-number=stepNumber)
        .row
            .col-xs-6.text-left.appcues-actions-left
                //- We have to show this button and hide it with CSS to support
                //- older steps that don't have a stepNumber property.
                -var defaultText = 'Back'
                -var step = 'prev'
                -var buttonClass = ''
                -var alignment = 'LEFT'
                -var type = 'BACK'
                -var visible = !isFirst

                if leftButtons
                    +stepButtons(leftButtons, defaultText, step, isEditable, hasBetaSDK, buttonClass, alignment, type, visible)

            .col-xs-6.text-right.appcues-actions-right
                -var step = isLast ? 'end' : 'next'
                -var defaultText = isLast ? 'OK, got it' : 'Next'
                -var buttonClass = 'appcues-button-success'
                -var alignment = 'RIGHT'
                -var type = 'NEXT'
                -var visible = true

                if rightButtons
                    +stepButtons(rightButtons, defaultText, step, isEditable, hasBetaSDK, buttonClass, alignment, type, visible)
        if showPoweredByText
            .row
                .col-xs-12.appcues-powered-by-text
                    a(href="https://my.appcues.com/subscription", target="_blank")
                        svg(xmlns="http://www.w3.org/2000/svg", viewBox="0 0 35.71 45.567", width="10px", height="10px")
                            polygon(style="fill: #bebebe" points="15.939 25.197 28.904 45.567 35.71 45.567 35.71 0 15.939 25.197")
                            polygon(style="fill: #bebebe" points="0 45.567 12.516 45.567 12.516 29.466 0 45.567")
                        span Powered By Appcues

