if showingTips
    .tips
        p Creating a video can be daunting, but it doesn't have to be so hard. Here are a few things to help you succeed with video.

        h4 Video creation:

        ul
            li
                div
                    a(href='http://quickcast.io/', target='_blank') Quickcast
                    small &nbsp;(Mac-only)
                    span.text-muted  - Free
                p Quickcast is a free, light-weight screencasting tool for Mac OS X. They even host the videos for you.

            li
                div
                    a(href='http://www.telestream.net/screenflow/overview.htm', target='_blank') ScreenFlow
                    span.text-muted  - $99
                p ScreenFlow does both screen recording and editing.

        h4 Video hosting:

        ul
            li
                div
                    a(href='http://wistia.com/', target='_blank') Wistia
                    span.text-muted  - Free
                p Wistia specializes in business video hosting. They're free and have a ton of great features.

        p When creating your video, be comfortable. Create a script beforehand and read it aloud several times. When doing a screencast, record the audio first, then listen to it while you record your screen. Don't do both things at once.

        p
            | For more on-camera tips, check out Wistia's free&nbsp;
            a(href='http://wistia.com/learning', target='_blank') Learning Center
            | .
            |  Those guys rock.

    .controls
        a.btn.btn-default.btn-sm.pull-right(data-action='toggleTips') Close

else
    .embed-code
        label(for='embedCode') Paste your video embed code here:
        textarea.form-control(name='embedCode' rows=7)= embedCode

    .controls.clearfix
        a.appcues-style.btn.btn-link.btn-sm.control-link(data-action='delete'): i.fa.fa-trash
        a.appcues-style.btn.btn-primary.btn-sm.pull-right(data-action='saveAndClose') Save
        a.appcues-style.btn.btn-default.btn-sm.pull-right(data-action='close') Cancel
        a.appcues-style.btn.btn-link.btn-sm.pull-right(data-action='toggleTips') Need video help?
