extends one-column
include mixins/integration

block title
    h2 Integrations

block content

    p Track how your users interact with your flows using the tools you already have installed.

    p
        strong How does this work?&nbsp;
        span Just activate, and we'll use the integration already installed on your site to send the data automatically.&nbsp;
        a(href="http://docs.appcues.com/article/137-integrations-overview", target="_blank") Learn more.

    br

    .integration-list
        for integration in integrations
            .integration-panel.panel.panel-default(class={ enabled: integration.isEnabled && integration.status !== 'pending' })
                .integration-panel-body.panel-body
                    +integration(integration)

    br

    .alert.alert-info.text-center
        p Don't see the tool you're looking for? Shoot us an email at #[a(href="mailto:support@appcues.com") support@appcues.com] to suggest it.

