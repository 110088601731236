.panel.panel-default
    .panel-heading Users

    table.table.users
        thead
            tr
                th
                    span.dropdown
                        a(data-toggle="dropdown")
                            = identityKey
                            | &nbsp;
                            i.fa.fa-angle-down
                        ul.dropdown-menu
                            for key in identityKeys
                                li
                                    a.set-identity-key(data-key=key)
                                        if key == identityKey
                                            strong= key
                                        else
                                            = key

                th.text-right(style="width: 110px") steps seen
                th.text-right(style="width: 110px") time spent
                th.text-right(style="width: 100px")
        tbody
            if visibleRows.length > 0
                tr
                for row in visibleRows
                    tr.user(data-user-id=row.identity.userId)
                        td
                            span(class=(identityKey == 'userId' ? '' : 'hint--right') data-hint=(identityKey === 'userId' ? false : "User ID: #{row.identity.userId}"))
                                if row.identity[identityKey] && row.identity[identityKey] !== ''
                                    = row.identity[identityKey]
                                else
                                    em.text-muted No value
                        td.text-right
                            span= row.latestStep + 1
                        td.text-right
                            span
                                | #{Math.round(row.timeSpent / 1000).toLocaleString()}s
                        td.text-right
                            small.text-muted.hint--left(data-hint=row.timeHint)= row.timeDisplay

                    tr.details(data-user-id=row.identity.userId)
                        td(colspan="4")
                            div.user-properties
                                table.table.table-bordered
                                    tbody
                                        for key in identityKeys
                                            if row.identity[key]
                                                tr
                                                    td.text-right(style="width: 20%")= key
                                                    td= row.identity[key]

                            if row.forms
                                div.form-responses
                                    h5 Form Responses
                                    table.table.table-bordered
                                        tbody
                                            for form in row.forms
                                                for field, fieldId in form
                                                    tr
                                                        td.text-right(style="width: 20%")= field.label
                                                        td
                                                            if field.value
                                                                = field.value
                                                            else
                                                                em.text-muted No response
            else
                tr.loading
                    td.text-muted.text-center(colspan="4")
                        br
                        br
                        i.fa.fa-circle-o-notch.fa-spin.fa-lg
                        br
                        br
                        br

    if totalRowCount > perPage
        .panel-footer
            .row
                .col-sm-4
                    if currentPage > 1
                        a.set-page(data-page=(currentPage - 1))
                            i.fa.fa-angle-left
                            | &nbsp;Previous
                .col-sm-4.text-center
                .col-sm-4.text-right
                    if totalRowCount > (perPage * currentPage)
                        a.set-page(data-page=(currentPage + 1))
                            | Next&nbsp;
                            i.fa.fa-angle-right


