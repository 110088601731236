if uploading
    .uploading
        .progress
            .progress-bar.progress-bar-primary.progress-bar-striped.active(style="width: #{uploadPercent}%")
        .status
            if uploadPercent === 100
                | Processing&hellip;
            else
                | Uploading #{Math.round(uploadPercent)}%

else if rawImageUrl || uploadedImageUrl
    .preview
        div.apc-img-wrap(style="text-align: #{position || 'center'}")
            a(href=link)
                img(style="width: #{width || 'auto'}" src=(rawImageUrl || uploadedImageUrl))
    .settings
        .row
            .col-sm-12
                label Link:
                br
                input.form-control(name='link' placeholder='http://example.com/' value=link)
        .row
            .col-sm-4
                label Location:
                br
                select.form-control(name='linkTarget')
                    option(value='_self' selected=(linkTarget == '_self')) Same Window
                    option(value='_blank' selected=(linkTarget == '_blank')) New Tab
            .col-sm-4
                label Width:
                br
                input.form-control(name='width' placeholder='auto' value=width)
            .col-sm-4
                label Position:
                br
                select.form-control(name='position')
                    option(value='center' selected=(position == 'center')) Center
                    option(value='left' selected=(position == 'left')) Left
                    option(value='right' selected=(position == 'right')) Right

else
    form.drop-area
        .message Drag an image here
        .divider or
        a.btn.btn-default.dropzone-btn Choose a file

div.controls.clearfix(class=(rendered ? 'no-animation' : ''))
    a.appcues-style.btn.btn-link.btn-sm.control-link(data-action='delete'): i.fa.fa-trash
    a.appcues-style.btn.btn-primary.btn-sm.pull-right(data-action='saveAndClose') Save
    a.appcues-style.btn.btn-default.btn-sm.pull-right(data-action='close') Cancel
    if uploadedImageUrl
        a.appcues-style.btn.btn-link.btn-sm.pull-right(data-action='resetForm') Reset
