extends modal

block header
    h4.modal-title Update Your Profile

block body
    if savingMeta || updatingPassword
        .row
            .col-md-12
                .alert.alert-info(role="alert")
                    strong
                        i.fa.fa-circle-o-notch.fa-spin
                        | &nbsp;Saving&hellip;

    else if saved
        .row
            .col-md-12
                .alert.alert-success(role="alert")
                    strong
                        i.fa.fa-check-circle
                        | &nbsp;Saved

    form
        .row
            .col-sm-3.text-center
                div
                    img(src=user.avatarUrl style="width:100%; border-radius: 4px")
                a.small(href="http://gravatar.com", target="_blank") Change on Gravatar
            .col-sm-9
                .form-group
                    label.control-label(for='name') Name
                    input.form-control(type='text' name='fullname' placeholder='Marty McFly' value=user.fullname)
                .form-group
                    label.control-label(for='role') Role
                    select.form-control(name='role')
                        option
                        for role in userRoles
                            option(value=role selected=(role === user.role))= role

block footer
    .row
        .col-md-12
            if passwordResetStatus == "pending"
                a.btn.btn-link.pull-left.disabled Sending password reset email...
            else
                a.btn.btn-link.pull-left(data-action='changePassword') Change your password
            if saved
                a.btn.btn-default(data-dismiss='modal') Close
            else
                a.btn.btn-default(data-dismiss='modal') Cancel
                button.btn.btn-primary(type='button') Save

    if passwordResetStatus
        .row.password-status
            .col-md-12(style="margin-top:12px; text-align:center;")
                if passwordResetStatus == "success"
                    .alert.alert-success(role="alert")
                        strong
                            i.fa.fa-check-circle
                            | &nbsp;Password reset email sent
                else if passwordResetStatus == "failed"
                    .alert.alert-error(role="alert")
                        strong
                            i.fa.fa-check-circle
                            | &nbsp;Error Resetting password
