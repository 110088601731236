nav(role='navigation')
    - var shouldShowFlowsProduct = !isNPSProductTrial && !isNPSProductOnlyUser
    .navbar-header
        .navbar-brand
            - var className = 'home hint--bottom'
            - var logoUrl = '/images/mark.png'
            - var imageSize = '20'
            - var showTip = window.location.pathname != '/'
            - var logoTip = showTip ? 'Back to your dashboard' : ''

            a(class=className, href='/', data-hint=logoTip)
                img.logo(src=logoUrl, height=imageSize)

    ul.nav.navbar-nav.navbar-left
        li(class = page.category == 'flows' && 'active'): a(href='/') Flows

        li(class = page.category == 'checklists' && 'active'): a(href='/checklists') Checklists

        li(class = page.category == 'satisfactionSurveys' && 'active'): a(href='/nps') NPS
        
        if shouldSeeMobileLink
            li(class = page.category == 'mobile' && 'active')
                a(href='https://www.appcues.com/mobile/ios') Mobile 
                .new-tooltip NEW
              

        if shouldShowFlowsProduct
            li.separator

        if shouldShowFlowsProduct
            li(class = page.category == 'styles' && 'active'): a(href='/styling').secondary Themes
        if shouldShowFlowsProduct
            li(class = page.category == 'segments' && 'active'): a(href='/segments').secondary Segments
        if shouldShowFlowsProduct
            li(class = page.category == 'goals' && 'active'): a(href='/goals').secondary Goals


    ul.nav.navbar-nav.navbar-right(style='margin-right:0px')
        if user.isAdmin
            li: p.navbar-text
                if auth.spoofing
                    span.label.label-danger: a.stop-spoofing(href="javascript: void(0);") Spoofing ##{accountId}
                else
                    form.spoof.navbar-form.navbar-input-group(style="padding-right:4px; margin:0; line-height:0;")
                        .input-group(style="top: 5px;")
                            input.input-sm.form-control(type='text', placeholder=accountId, name='accountId', style='width:70px;')
                            span.input-group-btn: button.btn.btn-danger.btn-sm Spoof!

        li.hidden-xs
            if isNPSProductTrial || isNPSProductOnlyUser
                p.navbar-text
                    if isNPSProductTrialExpired
                        a(href="/subscription").label.label-danger Upgrade
                    else
                        a(href="/subscription").label.label-info Upgrade
            else if account.isTrial && !isNPSProductTrial
                p.navbar-text
                    - var flowsShown = stats.allTimeFlowsShown || 0
                    span.navbar-flows-shown-module
                        a.navbar-flows-shown(href='http://docs.appcues.com/article/194-how-the-appcues-free-trial-works', target='_blank', title='Learn more about how this is calculated')
                            strong #{ flowsShown }
                            span.divide /
                            strong #{ account.flowsShownLimit }
                            span &nbsp;flows shown

                        span.navbar-flows-shown.navbar-flows-shown-beacon
                            i.fa.fa-question-circle(aria-hidden="true")
                            span.navbar-flows-shown-tooltip
                                span.flows-shown-header How is this calculated?
                                span.flows-shown-section Your trial is based on total times an Appcues flow has appeared.
                                span.flows-shown-section Since the numbers on the dashboard show unique users, these numbers might differ.

                        if account.isTrialExpired
                            a(href="/subscription").label.label-danger Upgrade
                        else
                            a(href="/subscription").label.label-info Upgrade
            else
                p.navbar-text: a(href="javascript:Appcues.show('-KpWA8E-Ls0abpDddq6n')")
                    small Want to test new features?

        li.dropdown(class = page.category == 'learn' && 'active')
            a(data-toggle='dropdown')
                i.fa.fa-question-circle
            ul#resources-menu.dropdown-menu(role='menu')
                li.dropdown-header Product Adoption Resources
                li: a(href="https://www.appcues.com/blog/appcues-ideas-and-inspirations?utm_source=internal&utm_medium=header_learn&utm_campaign=appcues-ideas-and-inspirations" target="_blank") Flow Ideas &amp; Inspirations&nbsp;
                    strong(style='color:#FFAA39; font-size: 13px') NEW
                li: a(href="https://www.appcues.com/webinars/on-demand-demo?utm_source=internal&utm_medium=header_learn&utm_campaign=on-demand-demo" target='_blank') Appcues On-Demand Demo
                li: a(href='http://www.appcues.com/user-onboarding-academy?utm_source=internal&utm_medium=learn&utm_campaign=/user-onboarding-academy/' target='_blank') User Onboarding Academy

                li.dropdown-header Blog Posts
                li: a(href="https://www.appcues.com/blog/announcing-appcues-2-0?utm_source=internal&utm_medium=header_learn&utm_campaign=announcing-appcues-2-0" target="_blank") Announcing Appcues 2.0&nbsp;
                    strong(style='color:#FFAA39; font-size: 13px') NEW
                li: a(href='https://www.appcues.com/blog/product-tours-when-to-use-which-ui-pattern/?utm_source=internal&utm_medium=learn&utm_campaign=/product-tours-when-to-use-which-ui-pattern/' target='_blank') When to Use What Pattern
                li: a(href='http://www.appcues.com/blog/?utm_source=internal&utm_medium=learn&utm_campaign=/blog/' target='_blank') Blog Home

                li.dropdown-header Support and Documentation
                li: a(href='http://docs.appcues.com/?utm_source=internal&utm_medium=learn&utm_campaign=docs' target='_blank') Help Center
                li: a(href='http://docs.appcues.com/category/45-getting-started?utm_source=internal&utm_medium=learn&utm_campaign=getting-started' target='_blank') Getting Started
                li: a(href='http://docs.appcues.com/collection/76-developer-docs?utm_source=internal&utm_medium=learn&utm_campaign=developer-docs' target='_blank') Developer Docs

        li.hidden-xs: a#taco(href='javascript:void(0)'): i.fa.fa-inbox.appcues-widget-icon

        li.dropdown(class = page.category == 'settings' && 'active')
            a.with-img.dropdown-toggle(href='#' data-toggle="dropdown")
                i.fa.fa-gear
                img.avatar.img-rounded(src=user.avatarUrl, width='30')
            ul#user-menu.dropdown-menu(role='menu')
                li.dropdown-header Manage

                li: a(href='/account') Account
                if !hideInviteUser
                    li: a(href='/team') Team
                li: a(href='/subscription') Subscription

                li.dropdown-header Configure

                li: a(href='/install/#{accountId}') Installation
                if !isNPSProductTrial
                    li: a(href='/integrations') Integrations
                li: a(href='/events-attributes') Events and Attributes

                li.dropdown-header Reference

                if user.isAdmin
                    li: a(href='/install/status/#{accountId}') Status
                if shouldShowFlowsProduct
                    li: a(href='/diagnostics') Flow Diagnostics
                li: a(href='http://docs.appcues.com/' target="_blank") Help Center

                li.dropdown-header Status

                li.application-status: a(href='http://status.appcues.com/' target="_blank")
                    if status.indicator
                        span.status-dot(class='status-#{status.indicator}')
                    span.status-description= status.description || 'Application Status'

                li.divider
                li: a(href='/logout') Log out
