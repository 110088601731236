.radio(class=(ruleHasDomains ? '' : 'active'))
    label
        input(type="radio" name="toggleDomains" value="all" checked=(!ruleHasDomains) disabled=isPublished)
        span
        | Any domains where my Appcues embed script is installed
.radio(class=(ruleHasDomains ? 'active' : ''))
    label
        input(type="radio" name="toggleDomains" value="select" checked=ruleHasDomains disabled=isPublished)
        span
        | Only the domains I choose

    .content.select-domains
        if accountHasDomains
            .row
                for domain in domains
                    .col-sm-6.col-md-4.col-lg-3
                        .checkbox
                            label
                                input(type='checkbox' name='domains' value=domain.name checked=domain.selected disabled=isPublished)
                                span
                                = domain.name
            a.btn.btn-link(href="/account" target="_blank") Add domains
        else
            p
                | You haven't added any domains to your account yet. Once you do, you can target this flow to show only on specific domains.
            a.btn.btn-default.btn-sm(href="/account" target="_blank") Add domains

