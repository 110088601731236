unless isEmpty && isFiltered
    if group
        - var chevronDir = isCollapsed ? 'right' : 'down'
        div.group-header.toggle-collapse(class=(isCollapsed ? 'text-muted' : ''))
            h4
                span.fa.fa-fw(class='fa-chevron-#{chevronDir}')
                = group.name

            // DISABLE BATCH ACTION MENU FOR NOW - Tristan 12/16
            if !isEmpty
                small.pull-right
                    a.fa.fa-ellipsis-h.batch-actions(data-toggle='dropdown')
                    ul.dropdown-menu
                        li.dropdown-header Group actions

                        li
                            if cannotPublish
                                a: span.text-muted Publish all
                            else
                                a(data-action='batch-publish' data-property='group' data-value=group.name) Publish all
                        li
                            a(data-action='batch-unpublish' data-property='group' data-value=group.name) Unpublish all

            if group.totals
                small.pull-right.hidden-sm
                    span
                        if isFiltered && (totalHidden || group.totals.active == 0)
                            | #{total} #{s.pluralize(total, 'flow')} (#{totalHidden} hidden by filters)
                        else
                            | #{group.totals.active} #{s.pluralize(group.totals.active, 'flow')}
                            if group.totals.archived
                                | &nbsp;(#{group.totals.archived} archived)

    unless isCollapsed
        if isEmpty
            p.text-muted.text-center Nothing here. Drag and drop content to reorganize.
            br
        else
            table.content-table.table-layout
                tbody

