if !shouldShowTemplates && !shouldHideFlowsDashboard
    .main-row
        .container
            if checkedForCrx && !hasCrxInstalled
                if onChrome
                    .notice.alert-info
                        p
                            b You'll need the Appcues Chrome extension
                            | &nbsp;to create and edit flows directly in your app. Please install and enable the extension.&nbsp;
                        a.btn.btn-info.create-flow-btn Install
                else
                    .notice.alert-warning
                        p
                            | It looks like you're not on Chrome.&nbsp;
                            b You'll need to use Chrome with the Appcues Chrome extension
                            | &nbsp;to create and edit flows.&nbsp;
                        a.btn.btn-warning(href="https://www.google.com/chrome/browser/desktop/index.html" target="_blank") Install Chrome
                        a.btn.btn-outline.btn-outline-warning(href="http://docs.appcues.com/article/238-how-to-use-the-appcues-editor" target="_blank") Learn More

            .row.row-flex.row-flex-centered.row-flex-justify
                .col-md-6.half
                    h1 Your Flows
                .col-md-6.half.row-flex.row-flex-right
                    .page-actions.create-bar
                        if isAnnouncementsBeta
                            a.btn.btn-success.create-announcement(href="/announcements/new/edit") Create Announcement
                        a.btn.btn-success(data-action='createContentWithDomain') Create Flow

            .filters-bar

            .row
                .col-md-12
                    .main-content

if shouldShowTemplates && !shouldHideFlowsDashboard
    .main-row.setup-page
        .container
            .template-welcome
                h1 This is your Appcues Dashboard
                p(style='margin-bottom: 34px;') Looks like you don't have any flows. Creating one is easy!
                .create-new-flow
                    button.create-flow-btn.btn.btn-success Create a Flow

else if shouldHideFlowsDashboard
    .main-content#apc-react-root
        unless loaded
            .spinner

if window.location.href.match(/\/setup/)
    script.
        (function() {
        var _fbq = window._fbq || (window._fbq = []);
        if (!_fbq.loaded) {
            var fbds = document.createElement('script');
            fbds.async = true;
            fbds.src = '//connect.facebook.net/en_US/fbds.js';
            var s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(fbds, s);
            _fbq.loaded = true;
        }
        })();
        window._fbq = window._fbq || [];
        window._fbq.push(['track', '6032107870532', {'value':'0.00','currency':'USD'}]);
    noscript.
        <img height="1" width="1" alt="" style="display:none" src="https://www.facebook.com/tr?ev=6032107870532&amp;cd[value]=0.00&amp;cd[currency]=USD&amp;noscript=1" />
