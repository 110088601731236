.container
    .row
        .col-md-4.col-md-offset-4

            .auth-page.text-center

                img.logo(src='/images/mark.png', width='35', height='44')

                p Welcome to Appcues! We're so glad you're here. Just put in the temporary password we sent you to activate your Appcues account and get started!
                .panel.panel-default

                    .panel-body
                        block content
                            - var formClass = forgotPassword ? 'forgot-password' : 'login-form'
                            form(class=formClass, role='form')
                                .form-group
                                    input.form-control(name='email', type='email', placeholder='Email', readonly, value=email)

                                if forgotPassword
                                    button.btn.btn-block.btn-primary(type='submit') Send me a new password
                                else
                                    .form-group
                                        input.form-control(name='password', type='password', autofocus='autofocus', placeholder='Password')

                                    button.btn.btn-block.btn-primary(type='submit') Activate account

                            if noAccount
                                br
                                .alert.alert-warning
                                    strong Hmm...
                                    |  It doesn't look like you were invited to this account. Email us at
                                    a(href="mailto:support@appcues.com")  support@appcues.com
                                    |  if you think this is wrong.

                            if errorActivating
                                br
                                .alert.alert-warning
                                    strong Uh oh.
                                    |  It looks like we had some trouble activating your account. Shoot us an email at
                                    a(href="mailto:support@appcues.com")  support@appcues.com
                                    |  and we'll get this straightened out.

                .validation-error.hidden
                    block validation
                        .alert.alert-warning
                            strong Whoops!
                            |  We had an error logging you in. Check that everything looks good and try again.

                if forgotPassword
                    if error
                        .alert.alert-warning We were unable to reset your password. Are you sure you signed up?
                    else if success
                        .alert.alert-success Woot! Your new password is on it's way.

                    p: a.toggle-form(data-forgot='false') Actually, I remember my password now.
                else
                    p
                        | Forgot your password?&nbsp;
                        a.toggle-form(data-forgot='true') Send yourself a new one.
