div.field-label
    if isEditable
        input.label-value(placeholder="Add label..." value=label)
        .field-actions
            a.field-action.field-drag-handle.hint--left.control-link(data-hint="Drag and drop to reorder fields")
                i.fa.fa-sort
            .btn-group
                a.field-action.field-options.control-link(data-hint="Field options" role="button" tabindex="-1")
                    i.fa.fa-cog
            a.field-action.hint--left.control-link(data-action="removeField" data-hint="Delete this field")
                i.fa.fa-trash-o
    else
        label.label-display(for=id) #{label}

div.field-controls
    if isEditable
        textarea.placeholder-value(rows='4' placeholder="Click to add placeholder text") #{placeholder}
    else
        textarea.placeholder-value(name=id rows='4' placeholder=placeholder)

