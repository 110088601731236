if uploading
    .uploading
        .progress
            .progress-bar.progress-bar-primary.progress-bar-striped.active(style="width: #{uploadPercent}%")
        .status
            if uploadPercent === 100
                | Processing&hellip;
            else
                | Uploading #{Math.round(uploadPercent)}%

else if rawImageUrl || uploadedImageUrl
    .preview
        div.apc-hero(style='background-image: url(#{rawImageUrl || uploadedImageUrl})')
            h1(name='heading', placeholder='Add Your Headline Here', contenteditable)
                = heading
            h2(name='subheading', placeholder='Enter a punchy subheadline in this area.', contenteditable)
                = subheading
else
    form.drop-area
        .message Drag an image here
        .divider or
        a.btn.btn-default.dropzone-btn Choose a file

div.controls.clearfix(class=(rendered ? 'no-animation' : ''))
    a.appcues-style.btn.btn-link.btn-sm.control-link(data-action='delete'): i.fa.fa-trash
    a.appcues-style.btn.btn-primary.btn-sm.pull-right(data-action='saveAndClose') Save
    a.appcues-style.btn.btn-default.btn-sm.pull-right(data-action='close') Cancel
    if uploadedImageUrl
        a.appcues-style.btn.btn-link.btn-sm.pull-right(data-action='resetForm') Reset
